<template>
<v-container style="padding-top:10%" >
<!-- <v-container style="padding-top:10%" fill-height> -->
    <v-row justify='center' align="center" outline>
        <v-col cols="10" style="padding-left:0%">
            <!-- <v-card-title class="primary--text font-weight-black"> -->
            <v-img height="100%" width="50%" :src="require('../assets/Icon-10.png')" />
            <!-- </v-card-title> -->
        </v-col>
        <v-col cols="12">
            <v-card elevation="4" width="100%" height="100px"  style="border-radius: 15px;" @click="menu('Booking') ">
                <!-- <v-img style="border-radius: 15px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="text-center">
                                <v-img height="80%" width="80%" :src="require('../assets/Icon-11.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="7" class="white--text">จองห้องประชุม</v-col>
                        </v-row>
                    </v-container>
                </v-img> -->
                <v-img style="border-radius: 10px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1" style="padding-left:20px"></v-col>
                            <v-col  cols="4" class="text-center">
                                <v-img height="20%" width="20%" :src="require('../assets/Icon-11.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="6" class="white--text"><p style="font-size: 30px;margin: 0px;">จองห้องประชุม</p></v-col>
                        </v-row>
                    </v-container>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card elevation="4" width="100%" height="100px" outlined style="border-radius: 15px;" @click="menu('Calender')">
                <!-- <v-img style="border-radius: 15px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="text-center">
                                <v-img height="80%" width="80%" :src="require('../assets/Icon-32-32.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="7" class="white--text">แสดงการจองห้องประชุม</v-col>
                        </v-row>
                    </v-container>
                </v-img> -->
                <v-img style="border-radius: 10px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1" style="padding-left:20px"></v-col>
                            <v-col  cols="4" class="text-center">
                                <v-img height="20%" width="20%" :src="require('../assets/Icon-32-32.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="6" class="white--text"><p style="font-size: 30px;margin: 0px;">แสดงการจองห้องประชุม</p></v-col>
                        </v-row>
                    </v-container>
                </v-img>
            </v-card>
        </v-col>
        
    </v-row>

</v-container>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'

export default {
    data: vm => ({
        startdate: new Date().toISOString().substr(0, 10),
        menustartdate: false,
        enddate: new Date().toISOString().substr(0, 10),
        menuenddate: false,

        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        enddateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),

        startime: null,
        menustarttime: false,
        endtime: null,
        menuendtime: false,

        listroom: false
    }),
    components: {},
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
        computedDateFormatted() {
            return this.formatDate(this.startdate)
        },
    },
    watch: {
        startdate(val) {
            this.dateFormatted = this.formatDate(this.startdate)
        },
        enddate(val) {
            this.enddateFormatted = this.formatDate(this.enddate)
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        menu(menu) {
            // this.$store.dispatch("users/menu", menu)
            // console.log(menu)
            this.$router.push({
                name: menu
            });

        },
    },
    created() {
        if (this.$store.state.users.user.token == null) {
            this.$router.push({
                name: 'Login'
            });
        }
    }
}
</script>

<style>
.box {
    background: rgb(108, 118, 128);
    background: linear-gradient(176deg, rgba(108, 118, 128, 1) 0%, rgba(83, 92, 109, 1) 100%);
}
</style>
