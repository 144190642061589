<template>
<v-container style="padding-top:10vh">
    <v-row justify='start' style="padding-left:3%;padding-right:3%">
        <v-card-title class="black--text font-weight-black">
            เลือกอุปกรณ์ในการประชุม
        </v-card-title>

    </v-row>
    <v-row style="padding-top:0%;padding-left:18vh;padding-right:10vh" justify='end' outline>
        <!-- เลือกหลายรายการ
        <v-col cols="1" v-if="!user.mult" style="padding:0px;padding-right: 0%;">
            <v-btn v-if="!user.mult" x-small rounded color="grey lighten-2" class="black--text" dark @click="selectmult()">
                เลือกหลายรายการ
            </v-btn>
        </v-col> -->

        <!-- <v-col cols="1" v-if="user.mult" style="padding:0px;padding-right: 35%;">
            <v-btn x-small rounded class="bax" dark @click="selectmult()">
                <v-icon color="gray" small @click="showinformation()">
                    mdi-information-outline
                </v-icon>
            </v-btn>
        </v-col> -->
        <v-col cols="1" style="padding:0px;padding-right: 0%;" v-if="user.mult" @click="menu('Detailquipments')">
            <v-badge small overlap color="red" :content="count">
                <v-icon color="gray" medium>
                    mdi-cart
                </v-icon>
            </v-badge>
        </v-col>
        <v-col cols="1" v-if="user.mult" style="padding:0px;padding-right: 0%;">
            <v-btn x-small rounded color="box" dark @click="clear()">
                ยกเลิกรายการ
            </v-btn>
        </v-col>
        <!-- <v-col cols="1" v-if="user.mult"></v-col> -->
    </v-row>
    <v-row  style="padding-top:0%;padding-left:3%;padding-right:3%" justify='start' outline>

        <v-col v-if="user.countlistequipments[0].status" cols="6" style="padding-right:5px">
            <!-- <v-hover v-slot="{ hover }" open-delay="200">
                <v-card elevation="0" :color="hover ? 'box' : 'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" :class="{ 'on-hover': hover }" @click="selectitem('Video Conference')">
                    <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                        <template v-slot:activator>
                            <v-icon color="gray" small @click="showinformation()">
                                mdi-information-outline
                            </v-icon>
                        </template>
                    </v-speed-dial>
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-img v-if="!hover" contain height="60px" width="60px" :src="require('../assets/Icon-25.png')" />
                            <v-col v-if="!hover" cols="12" class="back--text text-center">Video Conference</v-col>

                            <v-img v-if="hover" contain height="60px" width="60px" :src="require('../assets/Icon-19.png')" />
                            <v-col v-if="hover" cols="12" class="white--text text-center">Video Conference</v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-hover> -->

            <v-card elevation="0" :color="item['Video Conference'] ? 'box' :'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" @click="selectitem('Video Conference')">
                <v-speed-dial  v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                    <template v-slot:activator>
                        <v-icon :color="item['Video Conference'] ? 'white' :'black'" small @click="showinformation('Video Conference')">
                            mdi-information-outline
                        </v-icon>
                    </template>
                </v-speed-dial>
                <v-container fill-height fluid>
                    <v-row justify='center' align="center">
                        <v-img v-if="!item['Video Conference']" contain height="60px" width="60px" :src="require('../assets/Icon-25.png')" />
                        <v-col v-if="!item['Video Conference']" cols="12" class="back--text text-center">Video Conference</v-col>

                        <v-img v-if="item['Video Conference']" contain height="60px" width="60px" :src="require('../assets/Icon-19.png')" />
                        <v-col v-if="item['Video Conference']" cols="12" class="white--text text-center">Video Conference</v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>

        <v-col v-if="user.countlistequipments[1].status" cols="6" style="padding-left:5px">
            <!-- <v-hover v-slot="{ hover }" open-delay="200">
                <v-card elevation="0" :color="hover ? 'box' : 'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" :class="{ 'on-hover': hover }" @click="selectitem('Interactive Board')">
                    <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                        <template v-slot:activator>
                            <v-icon color="gray" small @click="showinformation()">
                                mdi-information-outline
                            </v-icon>
                        </template>
                    </v-speed-dial>
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-img v-if="!hover" contain height="60px" width="60px" :src="require('../assets/Icon-26.png')" />
                            <v-col v-if="!hover" cols="12" class="back--text text-center">Interactive Board</v-col>

                            <v-img v-if="hover" contain height="60px" width="60px" :src="require('../assets/Icon-20.png')" />
                            <v-col v-if="hover" cols="12" class="white--text text-center">Interactive Board</v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-hover> -->
            <v-card elevation="0" :color="item['Interactive Board'] ? 'box' :'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" @click="selectitem('Interactive Board')">
                <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                    <template v-slot:activator>
                        <v-icon :color="item['Interactive Board'] ? 'white' :'black'" small @click="showinformation('Interactive Board')">
                            mdi-information-outline
                        </v-icon>
                    </template>
                </v-speed-dial>
                <v-container fill-height fluid>
                    <v-row justify='center' align="center">
                        <v-img v-if="!item['Interactive Board']" contain height="60px" width="60px" :src="require('../assets/Icon-26.png')" />
                        <v-col v-if="!item['Interactive Board']" cols="12" class="back--text text-center">Interactive Board</v-col>

                        <v-img v-if="item['Interactive Board']" contain height="60px" width="60px" :src="require('../assets/Icon-20.png')" />
                        <v-col v-if="item['Interactive Board']" cols="12" class="white--text text-center">Interactive Board</v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>

        <v-col v-if="user.countlistequipments[2].status" cols="6" style="padding-right:5px">
            <!-- <v-hover v-slot="{ hover }" open-delay="200">
                <v-card elevation="0" :color="hover ? 'box' : 'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" :class="{ 'on-hover': hover } " @click="selectitem('Microphone')">
                    <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                        <template v-slot:activator>
                            <v-icon color="gray" small @click="showinformation()">
                                mdi-information-outline
                            </v-icon>
                        </template>
                    </v-speed-dial>
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-img v-if="!hover" contain height="60px" width="60px" :src="require('../assets/Icon-27.png')" />
                            <v-col v-if="!hover" cols="12" class="back--text text-center">Microphone</v-col>

                            <v-img v-if="hover" contain height="60px" width="60px" :src="require('../assets/Icon-21.png')" />
                            <v-col v-if="hover" cols="12" class="white--text text-center">Microphone</v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-hover> -->
            <v-card elevation="0" :color="item['Microphone'] ? 'box' :'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" @click="selectitem('Microphone')">
                <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                    <template v-slot:activator>
                        <v-icon :color="item['Microphone'] ? 'white' :'black'" small @click="showinformation('Microphone')">
                            mdi-information-outline
                        </v-icon>
                    </template>
                </v-speed-dial>
                <v-container fill-height fluid>
                    <v-row justify='center' align="center">
                        <v-img v-if="!item['Microphone']" contain height="60px" width="60px" :src="require('../assets/Icon-27.png')" />
                        <v-col v-if="!item['Microphone']" cols="12" class="back--text text-center">Microphone</v-col>

                        <v-img v-if="item['Microphone']" contain height="60px" width="60px" :src="require('../assets/Icon-21.png')" />
                        <v-col v-if="item['Microphone']" cols="12" class="white--text text-center">Microphone</v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>

        <v-col v-if="user.countlistequipments[3].status" cols="6" style="padding-left:5px">
            <!-- <v-hover v-slot="{ hover }" open-delay="200">
                <v-card elevation="0" :color="hover ? 'box' : 'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" :class="{ 'on-hover': hover }" @click="selectitem('Wireless Sharing')">
                    <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                        <template v-slot:activator>
                            <v-icon color="gray" small @click="showinformation()">
                                mdi-information-outline
                            </v-icon>
                        </template>
                    </v-speed-dial>
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-img v-if="!hover" contain height="60px" width="60px" :src="require('../assets/Icon-28.png')" />
                            <v-col v-if="!hover" cols="12" class="back--text text-center">Wireless Sharing</v-col>

                            <v-img v-if="hover" contain height="60px" width="60px" :src="require('../assets/Icon-22.png')" />
                            <v-col v-if="hover" cols="12" class="white--text text-center">Wireless Sharing</v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-hover> -->
            <v-card elevation="0" :color="item['Wireless Sharing'] ? 'box' :'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" @click="selectitem('Wireless Sharing')">
                <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                    <template v-slot:activator>
                        <v-icon :color="item['Wireless Sharing'] ? 'white' :'black'" small @click="showinformation('Wireless Sharing')">
                            mdi-information-outline
                        </v-icon>
                    </template>
                </v-speed-dial>
                <v-container fill-height fluid>
                    <v-row justify='center' align="center">
                        <v-img v-if="!item['Wireless Sharing']" contain height="60px" width="60px" :src="require('../assets/Icon-28.png')" />
                        <v-col v-if="!item['Wireless Sharing']" cols="12" class="back--text text-center">Wireless Sharing</v-col>

                        <v-img v-if="item['Wireless Sharing']" contain height="60px" width="60px" :src="require('../assets/Icon-22.png')" />
                        <v-col v-if="item['Wireless Sharing']" cols="12" class="white--text text-center">Wireless Sharing</v-col>
                    </v-row>
                </v-container>
            </v-card>

        </v-col>

        <v-col v-if="user.countlistequipments[4].status" cols="6" style="padding-right:5px">
            <!-- <v-hover v-slot="{ hover }" open-delay="200">
                <v-card elevation="0" :color="hover ? 'box' : 'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" :class="{ 'on-hover': hover }" @click="selectitem('Tablet')">
                    <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                        <template v-slot:activator>
                            <v-icon color="gray" small @click="showinformation()">
                                mdi-information-outline
                            </v-icon>
                        </template>
                    </v-speed-dial>
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-img v-if="!hover" contain height="60px" width="60px" :src="require('../assets/Icon-29.png')" />
                            <v-col v-if="!hover" cols="12" class="back--text text-center">Tablet</v-col>

                            <v-img v-if="hover" contain height="60px" width="60px" :src="require('../assets/Icon-23.png')" />
                            <v-col v-if="hover" cols="12" class="white--text text-center">Tablet</v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-hover> -->
            <v-card elevation="0" :color="item['Tablet'] ? 'box' :'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" @click="selectitem('Tablet')">
                <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                    <template v-slot:activator>
                        <v-icon :color="item['Tablet'] ? 'white' :'black'" small @click="showinformation('Tablet')">
                            mdi-information-outline
                        </v-icon>
                    </template>
                </v-speed-dial>
                <v-container fill-height fluid>
                    <v-row justify='center' align="center">
                        <v-img v-if="!item['Tablet']" contain height="60px" width="60px" :src="require('../assets/Icon-29.png')" />
                        <v-col v-if="!item['Tablet']" cols="12" class="back--text text-center">Tablet</v-col>

                        <v-img v-if="item['Tablet']" contain height="60px" width="60px" :src="require('../assets/Icon-23.png')" />
                        <v-col v-if="item['Tablet']" cols="12" class="white--text text-center">Tablet</v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
        <v-col  v-if="user.countlistequipments[5].status" cols="6" style="padding-left:5px">
            <!-- <v-hover v-slot="{ hover }" open-delay="200">
                <v-card elevation="0" :color="hover ? 'box' : 'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" :class="{ 'on-hover': hover }" @click="selectitem('Notebook')">
                    <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                        <template v-slot:activator>
                            <v-icon color="gray" small @click="selectmult()">
                                mdi-information-outline
                            </v-icon>
                        </template>
                    </v-speed-dial>
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-img v-if="!hover" contain height="60px" width="60px" :src="require('../assets/Icon-30.png')" />
                            <v-col v-if="!hover" cols="12" class="back--text text-center">Notebook</v-col>

                            <v-img v-if="hover" contain height="60px" width="60px" :src="require('../assets/Icon-24.png')" />
                            <v-col v-if="hover" cols="12" class="white--text text-center">Notebook</v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-hover> -->
            <v-card elevation="0" :color="item['Lcd'] ? 'box' :'grey lighten-2'" width="100%" outlined height="150px" style="border-radius: 15px" @click="selectitem('Lcd')">
                <v-speed-dial v-if="user.mult" style="position: absolute; " :top="true" :right="true">
                    <template v-slot:activator>
                        <v-icon :color="item['Lcd'] ? 'white' :'black'" small @click="showinformation('Lcd')">
                            mdi-information-outline
                        </v-icon>
                    </template>
                </v-speed-dial>
                <v-container fill-height fluid>
                    <v-row justify='center' align="center">
                        <v-img v-if="!item['Lcd']" contain height="60px" width="60px" :src="require('../assets/Icon-26.png')" />
                        <v-col v-if="!item['Lcd']" cols="12" class="back--text text-center">Lcd</v-col>

                        <v-img v-if="item['Lcd']" contain height="60px" width="60px" :src="require('../assets/Icon-20.png')" />
                        <v-col v-if="item['Lcd']" cols="12" class="white--text text-center">Lcd</v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>

    </v-row>
</v-container>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'

export default {
    data: vm => ({
        mult: false,
        count: "0",
        item: {
            'Video Conference': false,
            'Interactive Board': false,
            'Microphone': false,
            'Wireless Sharing': false,
            'Tablet': false,
            'Lcd': false
        }
    }),
    components: {},
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
        computedDateFormatted() {
            return this.formatDate(this.startdate)
        },
    },
    watch: {
        startdate(val) {
            this.dateFormatted = this.formatDate(this.startdate)
        },
        enddate(val) {
            this.enddateFormatted = this.formatDate(this.enddate)
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        menu(menu) {
            if (this.count != "0") {
                this.$router.push({
                    name: menu
                });
            }
        },
        selectmult() {
            this.$store.dispatch("users/selectmult");
        },
        showinformation(item) {
            console.log(this.$store.state.users.user.selectequipments)
            this.$router.push({
                name: "Detailquipments"
            });
        },
        selectitem(item) {
            // console.log(this.$store.state.users.user.mult)
            if (this.$store.state.users.user.mult == true) {
                this.item[item] = !this.item[item]
                if (this.item[item] == true) {
                    this.count++
                } else if (this.item[item] == false) {
                    this.count--
                }
            } else if (this.$store.state.users.user.mult == false) {
                this.$store.state.users.user.selectequipments = item
                this.$router.push({
                    name: "Detailquipments"
                });
            }
        },
        clear() {
            this.count = "0"
            this.item['Video Conference'] = false
            this.item['Interactive Board'] = false
            this.item['Microphone'] = false
            this.item['Wireless Sharing'] = false
            this.item['Tablet'] = false
            this.item['Lcd'] = false
            this.$store.dispatch("users/selectmult")
        }

    },
    created() {
        if (this.$store.state.users.user.token == null) {
            this.$router.push({
                name: 'Login'
            });
        }
        this.$store.dispatch("users/showlistequipments");
        // console.log("create")
    }
}
</script>

<style>
.box {
    background: rgb(108, 118, 128);
    background: linear-gradient(176deg, rgba(108, 118, 128, 1) 0%, rgba(83, 92, 109, 1) 100%);
}
</style>
