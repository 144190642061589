<template>
<v-container style="padding-top:5vh">
    <v-speed-dial style="position: absolute; padding-top:8%;padding-right:2%" :top="true" :right="true">
        <template v-slot:activator>
            <v-icon color="grey" medium @click="menu('Home')">
                mdi-home-circle-outline
            </v-icon>
        </template>
    </v-speed-dial>
    <v-row justify='center'>
        <v-card-title class="back--text font-weight-black">
            ห้องประชุม
        </v-card-title>
    </v-row>
    <v-row style="padding-left:5%;padding-right:5%;">
        <v-col cols="12" style="padding: 5px">
            <v-card width="100%" height="105%" outlined style="border-radius: 15px;">
                <v-row style="padding-left:2%">
                    <v-col cols="12">
                        <v-card-title class="text_card--text" style="padding-left:5%;padding-bottom: 0px">
                            {{user.meetingroomname}}
                        </v-card-title>
                        <v-card-text class="text_card--text" style="padding-left:5%;padding-bottom: 0px">
                            {{user.meetingroomtitle}}
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row justify='center'>
                    <v-card width="80%" height="130" style="border-radius: 10px;" outlined color="blue-grey lighten-2">
                        <v-img height="100%" width="100%" :src="require('../assets/room_img.jpg')" />
                    </v-card>
                </v-row>
                <v-row style="padding-left:5%">
                    <v-card-text class="text_card--text" style="padding-left:5%;padding-bottom:0%">
                        ช่วงเวลาที่ใช้งานห้องประชุม
                    </v-card-text>
                </v-row>
                <v-row justify='center' style="padding:0">
                    <v-col cols="11" style="padding:0;padding-left:30px;">
                        <v-range-slider dense style="font-size: 8px;" class="text_card--text" v-model="user.timeusermeetingtroom" :tick-labels="seasons" step="1" tick-size="4" ticks="always" min="7" max="19">
                            <template v-slot:thumb-label="props">
                                {{props.value}}
                            </template>
                        </v-range-slider>
                    </v-col>
                </v-row>
                <v-row style="padding-left:10vh;padding-bottom:0%">
                    <v-card-text class="text_card--text" style="padding-left:2%; padding-top:0%;padding-bottom:0%">
                        อุปกรณ์ที่ใช้ในการประชุมพื้นฐาน
                    </v-card-text>
                    <!-- <v-col style="padding:0%; padding-left:5%" cols="5">
                        <v-card-text style="padding:0%" class="text_card--text">
                            <v-icon x-small color="text_card">mdi-brightness-1</v-icon>
                            กระดานไวท์บอร์ด
                        </v-card-text>
                    </v-col> -->
                    <!-- <v-col style="padding:0%; padding-left:5%" cols="5">
                        <v-card-text style="padding:0%" class="text_card--text">
                            <v-icon x-small color="text_card">mdi-brightness-1</v-icon>
                            จอLED
                        </v-card-text>
                    </v-col> -->
                    <v-col style="padding:0%;" v-for="item in user.listequipments" :key="item[0]" cols="3">
                        <v-card-text style="padding:0%" class="text_card--text">
                            <v-icon x-small color="text_card">mdi-brightness-1</v-icon>
                            {{item[0]}}
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row justify='center'>
                    <v-col cols="10">
                        <v-btn  color="primary" class="box" style="border-radius: 10px;" depressed width="100%" @click="menu('Listequipments')">
                            เพิ่มอุปกรณ์การประชุม
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- <v-row style="padding-left:2%;padding-bottom:0%" class="green--text">
                    <v-card-text class="" style="padding-left:5%; padding-top:0%;padding-bottom:0%">
                        อุปกรณ์ที่ใช้ในการประชุมเพิ่มเติม
                    </v-card-text>
                    <v-col style="padding:0%; padding-left:5%" cols="10">
                        <v-card-text style="padding:0%" chips>
                            <v-icon x-small color="text_card">mdi-brightness-1</v-icon>
                            Video Conference
                        </v-card-text>
                    </v-col>
                </v-row> -->
            <v-row style="padding-left:2%;padding-bottom:0%" class="green--text">
                <v-card-text class="text_card--text" style="padding-left:5%; padding-top:2%;padding-bottom:0%">
                    อุปกรณ์ที่ใช้ในการประชุมเพิ่มเติม
                </v-card-text>
                <v-col style="padding:0%; padding-left:5%" cols="10">
                    <v-sheet class="mx-auto" max-width="100%">
                        <v-slide-group v-model="model">
                            <v-slide-item v-for="n in 6" :key="n" v-slot="{ active, toggle }">
                                <!-- {{user.showmorequipments[n]}} -->
                                <v-card v-if="user.showmorequipments[n] === 'true'" :color="active ? 'grey lighten-1' : 'grey lighten-1'" class="ma-3" height="90px" width="90px" style="border-radius: 10px" @click="toggle">
                                    <v-container fill-height fluid>
                                        <v-row justify='center' align="center">
                                            <!-- {{n-1}} -->
                                            <!-- {{imgmoreequipment[n-1]}} -->
                                            <v-img contain height="60px" width="60px" :src="image[n-1]" />
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'
import icon1 from "../assets/Icon-19.png"
import icon2 from "../assets/Icon-20.png"
import icon3 from "../assets/Icon-21.png"
import icon4 from "../assets/Icon-22.png"
import icon5 from "../assets/Icon-23.png"
import icon6 from "../assets/Icon-20.png"

export default {
    data: () => ({
        value: [8, 18],
        equipment: false,
        model: null,
        image : [icon1,icon2,icon3,icon4,icon5,icon6],
        seasons: [
            '',
            '8.00',
            '9.00',
            '10.00',
            '11.00',
            '12.00',
            '13.00',
            '14.00',
            '15.00',
            '16.00',
            '17.00',
            '18.00',

        ],
        icons: [
            'mdi-snowflake',
            'mdi-leaf',
            'mdi-fire',
            'mdi-water',
        ],
        imgmoreequipment: ['../assets/Icon-19.png','../assets/Icon-20.png','../assets/Icon-21.png','../assets/Icon-22.png','../assets/Icon-23.png','../assets/Icon-24.png']
    }),
    components: {},
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
    },
    methods: {
        season(val) {
            return this.icons[val]
        },
    },
    methods: {
        menu(menu) {
            this.$router.push({
                name: menu
            });
        }

    },
    created() {
        if (this.$store.state.users.user.token == null) {
            this.$router.push({
                name: 'Login'
            });
        }
        this.$store.state.users.user.stateequipments = null
        // console.log(this.$store.state.users.user.showbooking)
        let payload = new FormData()
        payload.append('booking_id', this.$store.state.users.user.showbooking )
        this.$store.dispatch("users/getmoreequipment",payload)
    }
}
</script>

<style>
.box {
    background: rgb(108, 118, 128);
    background: linear-gradient(176deg, rgba(108, 118, 128, 1) 0%, rgba(83, 92, 109, 1) 100%);
}
</style>
