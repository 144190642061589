<template>
<v-container style="padding-top:10%" >
<!-- <v-container style="padding-top:10%" fill-height> -->
    <v-row justify='center' align="center" outline>
        <v-col cols="10" style="padding-left:0%">
            <!-- <v-card-title class="primary--text font-weight-black"> -->
            <v-img height="100%" width="50%" :src="require('../assets/logo_linkroom01.png')" />
            <!-- </v-card-title> -->
        </v-col>
        <v-col cols="12">
            <v-card elevation="4" width="100%" height="100px" style="border-radius: 15px;" @click="menu('Roommenu') ">
                <!-- <v-img style="border-radius: 15px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="text-center">
                                <v-img height="80%" width="80%" :src="require('../assets/Icon-11.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="7" class="white--text">ห้องประชุม</v-col>
                        </v-row>
                    </v-container>
                </v-img> -->
                <v-img style="border-radius: 10px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1" style="padding-left:20px"></v-col>
                            <v-col  cols="4" class="text-center">
                                <v-img height="20%" width="20%" :src="require('../assets/Icon-11.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="6" class="white--text"><p style="font-size: 30px;margin: 0px;">ห้องประชุม</p></v-col>
                        </v-row>
                    </v-container>
                </v-img>
            </v-card>
        </v-col>
        <!-- <v-col cols="12">
             <v-card elevation="4" width="100%" height="100px" style="border-radius: 15px;" @click="test() "> -->
                <!-- <v-img style="border-radius: 15px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="text-center">
                                <v-img height="80%" width="80%" :src="require('../assets/Icon-12.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="7" class="white--text">สั่งอาหาร - เครื่องดื่ม</v-col>
                        </v-row>
                    </v-container>
                </v-img> -->
                
                <!-- <v-img style="border-radius: 10px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1" style="padding-left:20px"></v-col>
                            <v-col  cols="4" class="text-center">
                                <v-img height="20%" width="20%" :src="require('../assets/Icon-12.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="6" class="white--text"><p style="font-size: 30px;margin: 0px;">สั่งอาหาร - เครื่องดื่ม</p></v-col>
                        </v-row>
                    </v-container>
                </v-img> -->
            <!-- </v-card>
        </v-col> -->
        <v-col cols="12">
            <v-card elevation="4" width="100%" height="100px" style="border-radius: 15px;" @click="menu('Calender') ">
                <!-- <v-img style="border-radius: 15px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1"></v-col>
                            <v-col cols="3" class="text-center">
                                <v-img height="80%" width="80%" :src="require('../assets/Icon-13.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="7" class="white--text">เพิ่มอุปกรณ์ในการประชุม</v-col>
                        </v-row>
                    </v-container>
                </v-img> -->
                <v-img style="border-radius: 10px;" :src="require('../assets/Icon-31-31-31.png')" height="100%" class="grey darken-4">
                    <v-container fill-height fluid>
                        <v-row justify='center' align="center">
                            <v-col cols="1" style="padding-left:20px"></v-col>
                            <v-col  cols="4" class="text-center">
                                <v-img height="20%" width="20%" :src="require('../assets/Icon-13.png')" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="6" class="white--text"><p style="font-size: 30px;margin: 0px;">เพิ่มอุปกรณ์ในการประชุม</p></v-col>
                        </v-row>
                    </v-container>
                </v-img>
            </v-card>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'

export default {
    data: vm => ({
        startdate: new Date().toISOString().substr(0, 10),
        menustartdate: false,
        enddate: new Date().toISOString().substr(0, 10),
        menuenddate: false,

        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        enddateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),

        startime: null,
        menustarttime: false,
        endtime: null,
        menuendtime: false,

        listroom: false
    }),
    components: {},
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
        computedDateFormatted() {
            return this.formatDate(this.startdate)
        },
    },
    watch: {
        startdate(val) {
            this.dateFormatted = this.formatDate(this.startdate)
        },
        enddate(val) {
            this.enddateFormatted = this.formatDate(this.enddate)
        },
    },
    methods: {
        test(){
            let payload = new FormData()
            payload.append('date', '2021-01-25')
            // console.log(payload)
            // this.$store.dispatch("users/test",payload)
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        menu(menu) {
            // this.$store.dispatch("users/menu", menu)
            // console.log(menu)
            this.$router.push({
                name: menu
            });

        },
        // getlink() {
        //     let user_data = this.$route.params.user_data.split(/_|&|:/)
        //     let payload = new FormData()
        //     payload.append('firstname', user_data[0])
        //     payload.append('lastname', user_data[1])
        //     payload.append('email', user_data[2])
        //     payload.append('phone', user_data[3])
        //     this.$store.dispatch("users/getuser", payload)
        // }
    },
    created() {
        if (this.$store.state.users.user.token == null) {
            this.$router.push({
                name: 'Login'
            });
        }
    }
}
</script>

<style>
.box {
    background: rgb(247, 101, 103);
    background: linear-gradient(0deg, rgba(247, 101, 103, 1) 0%, rgba(237, 63, 135, 1) 100%);
}
</style>
