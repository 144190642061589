export class User {
    constructor () {
        this.confirmdialog = false
        this.unavailabledialog = false
        this.unavailable = []
        // V0.11
        this.url = "https://wtc.co.th:5050"
        // this.url = "http://0.0.0.0:5050"
        this.token = null
        this.test = "banana"
        this.mult = false
        this.schedulelist = null
        this.meetingroomname =  null
        this.meetingroomtitle = null
        this.timeusermeetingtroom = []
        this.email = null
        this.statebooking = null
        this.stateequipments = null
        this.showlistbookingroom = false
        this.listroom = null
        this.countlistroom = 0
        this.alaert = false
        this.alaert_text = null
        this.listequipments = null
        this.selectequipments = null
        this.showbooking = null
        this.showmorequipments = []

        this.logo = null
        this.room = null

        this.numequipments ={
            "Video Conference":'1',
            "Interactive Board":'2',
            "Microphone":'3',
            "Wireless Sharing":'4',
            "Tablet":'5',
            "Lcd":'6',
        }
        this.countlistequipments =[
            {
                "maxcount": null,
                "status" : false
            },
            {
                "maxcount": null,
                "status" : false
            },
            {
                "maxcount": null,
                "status" : false
            },
            {
                "maxcount": null,
                "status" : false
            },
            {
                "maxcount": null,
                "status" : false
            },
            {
                "maxcount": null,
                "status" : false
            }
        ]
        this.language = 'eng'
        this.menu = {
            "thai" :{
                "booking" : "จองห้องประชุม",
                "search" : "ค้นหา",
                "cancel" : "ยกเลิก",
                "basic_meeting_equipment" : "อุปกรณ์ที่ใช้ในการประชุมพื้นฐาน",
                "advanced_meeting_equipment" : "อุปกรณ์ที่ใช้ในการประชุมเพิ่มเติม",
                "meeting_topic" : "หัวข้อประชุม",
                "titletopic" : "กรุณากรอกหัวข้อการประชุม",
                "useremail" : "อีเมล์ผู้ใช้งาน",
                "lableemail" : "กรอกอีเมล์ผู้ใช้งาน",
                "start" : "เวลาเริ่ม",
                "end" : "เวลาสิ้นสุด",
                "meeting_type" : "ประเภทการประชุม"
            },
            "eng" : {
                "booking" : "Booking",
                "search" : "search",
                "cancel" : "cancel",
                "basic_meeting_equipment" : "Basic meeting equipment",
                "advanced_meeting_equipment" : "Advanced meeting equipment",
                "meeting_topic" : "Meeting topic",
                "titletopic" : "Please enter topic ",
                "useremail" : "User email",
                "lableemail" : "Please enter email",
                "start" : "Start",
                "end" : "End",
                "meeting_type" : "Meeting type"
                
            }
        },
        this.roomemail = null
    }
}
