import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Booking from '../views/Booking.vue'
import Showroom from '../views/Showroom.vue'
import Listequipments from '../views/Listequipments'
import Detailquipments from '../views/Detailquipments'
import Roommenu from '../views/Roommenu'
import Calender from '../views/Calender'
import Login from '../views/Login'
import Register from '../views/Register'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/:user_data',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/:user_data',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/room/showroom',
    name: 'Showroom',
    component: Showroom
  },
  {
    path: '/equipments/showlist',
    name: 'Listequipments',
    component: Listequipments
  },
  {
    path: '/equipments/showdetail',
    name: 'Detailquipments',
    component: Detailquipments
  },
  {
    path: '/room/roommenu',
    name: 'Roommenu',
    component: Roommenu
  },
  {
    path: '/room/calendee',
    name: 'Calender',
    component: Calender
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
