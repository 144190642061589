<template>
<v-app>
    <v-container fill-height>
        <v-row justify='center' fill-height style="padding-top:20%">
            <v-container fill-height fluid>
                <v-row justify='center' align="center">
                    <v-img contain style="padding-top:20px" height="50px" :src="require('../assets/Icon-10.png')" /><br />
                </v-row>
            </v-container>
            <v-col cols="10">
                <!-- <v-card id="rounded-card" height="100%" :elevation="elevation"> -->
                <v-layout justify="start">
                    <v-flex xs10 md10 offset-xs1 style="padding-top:10%">
                        <p>Create your Account</p>
                        <v-text-field style="padding-top:2%" hide-details v-model="firstname" label="firstname" required outlined dense></v-text-field>
                        <v-text-field style="padding-top:5%" hide-details v-model="lastname" label="lastname" required outlined dense></v-text-field>
                        <v-text-field style="padding-top:5%" hide-details v-model="email" label="email" required outlined dense></v-text-field>
                        <!-- <v-text-field v-model="username" hide-details :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showpassword ? 'text' : 'password'" label="Password" required @click:append="showpassword = !showpassword" outlined dense></v-text-field> -->
                        <v-text-field style="padding-top:5%" v-model="password" hide-details :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showpassword ? 'text' : 'password'" label="Password" required @click:append="showpassword = !showpassword" outlined dense></v-text-field>
                        <v-text-field style="padding-top:5%" v-model="conpassword" hide-details :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showpassword ? 'text' : 'password'" label="Confirm Password" required @click:append="showpassword = !showpassword" outlined dense></v-text-field>
                        <v-layout wrap>
                            <v-flex xs1></v-flex>
                            <!-- <v-flex xs1>
                                    <v-checkbox class="shrink mr-0 mt-0" hide-details v-model="checkbox" light></v-checkbox>
                                </v-flex> -->

                            <v-flex xs5 />
                            <!-- <v-flex xs5 style="font-size:10px; padding-top:8px; padding-left:2%">Remember Me</v-flex> -->
                            <!-- <v-flex xs6 style="font-size:10px; padding-top:8px;padding-left:15%" @click="action('Forgotpassword')">Forget Password?</v-flex> -->
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-card-text class="text-center" style="padding-top:20%">
                    <div class="text-center">
                        <v-btn width="90%" color="primary" class="box" dark dense @click="login()">
                            Sign up
                        </v-btn>
                    </div>
                </v-card-text>
                <!-- <v-card-text class="text-center" style="font-size:10px; padding-top:60%">
                    <p>Don't have an account?
                        <b class="primary--text">Create new one</b>
                    </p>
                </v-card-text> -->
                <!-- </v-card> -->
            </v-col>
        </v-row>
        <v-row>
            <v-card-text class="text-center" style="font-size:10px;  padding-top:20%">
                <div class="text-center grey--text">
                    <!-- <p>Don't have an account?
                        <b class="primary--text" @click="action('Createuser')">Sign up</b>
                    </p> -->
                </div>
            </v-card-text>
        </v-row>

        <!-- <v-overlay :z-index="zIndex" :opacity="opacity" :value="user.waiting">
            <p style="padding-bottom:30px" class="title">Please wait</p>

            <v-progress-circular :size="100" :width="7" color="primary" indeterminate>
                <v-progress-circular :rotate="180" :size="70" :width="7" color="primary" indeterminate>

                </v-progress-circular>
            </v-progress-circular>
        </v-overlay> -->

        <!-- <v-snackbar v-model="user.returnapistatus">
            {{ user.returnapitext }}

            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="closesnackbar()">
                    <b v-if="user.returnapicode == 200">login</b>
                    <b v-if="user.returnapicode == 401">close</b>
                </v-btn>
            </template>
        </v-snackbar> -->

    </v-container>
</v-app>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'

export default {
    data: () => ({
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        conpassword: null,
        elevation: 2,
        showpassword: false,
        checkbox: false,
        zIndex: 0,
        opacity: 0.9,
    }),
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
        formlogin() {
            return (
                this.username &&
                this.password
            )
        },
        token() {
            return this.$store.state.users.user.token
        }
    },
    watch: {
        token() {
            this.$router.push({
                name: 'Home'
            });
        }
    },
    methods: {
        action(page) {
            this.$router.push({
                name: page
            });
        },
        login() {
            let payload = new FormData()
            payload.append('username', this.username)
            payload.append('password', this.password)
            this.$store.dispatch("users/login", payload)
            this.username = null
            this.password = null
        },
        closesnackbar() {
            this.$store.state.users.user.returnapistatus = false
        }
    },
    created() {
        if (this.$store.state.users.user.token !== null) {
            this.$router.push({
                name: 'Home'
            });
        }
    }

}
</script>

<style>
/* .rounded-card{
    border-radius: 10px;
} */
#rounded-card {
    border-radius: 20px;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, .2);
    box-shadow: 0 -1px 10px rgba(0, 0, 0, .2) !important;
}

/* #footer_card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, .2);
    box-shadow: 0 -1px 5px rgba(0, 0, 0, .2);
} */
.box {
    background: rgb(108, 118, 128);
    background: linear-gradient(176deg, rgba(108, 118, 128, 1) 0%, rgba(83, 92, 109, 1) 100%);
}
</style>
