import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            // primary: '#6c7680',// grey
            primary: '#F57C00',// grey
            bg_green : '#8fc748',
            cl_orange : '#F57C00',

            pr : '#CCCDCE',

            primary2: '#ef097f',// pink2 
            secondary: '#424242',//balck
            accent: '#82B1FF',//blue
            error: '#FF5252',//red
            info: '#2196F3',//blue
            success: '#4CAF50',//green
            warning: '#FFC107',//yellow
            text_card: '#8a8a8a'//text_card
          },
        },
      },
});
