/* globals Store */
import { User } from '../../models'
import axios from 'axios';

const state = {
    user: new User(),
}

const actions = {
    getimg({commit},payload){
        console.log(state.user.url)
        axios.post(state.user.url+'/linksroom/api/v1/logobyemail',payload)
        .then(response => {
            // console.log(response.data.data)
            commit('setimg',response.data.data)
        })
    },
    checkmember({commit}){
        let payload = new FormData()
            payload.append('username', 'admin_qhouse')
            payload.append('password', '1234')

        axios.post('https://register.lifelink.co.th:65274/login',payload)
        .then(response => {
            // console.log(response.data.data.token)
            const headers = {
            'Authorization' : response.data.data.token
            }
            payload = new FormData()
            axios.post('https://register.lifelink.co.th:65274/list_company',payload, {
                    headers: headers
                })
                .then(response => {
                    // console.log(response)

                })
    
        })

        // const headers = {
        //     'Authorization' : state.user.token
        // }
        // // con
        // axios.post(state.user.url+'/linksroom/api/v1/booking',payload, {
        //     headers: headers
        //   })
        //   .then(response => {
        //     // console.log(response.data.status_code)
        //     if(response.data.status_code == 201){

        //         let payload = true
        //         // commit("setstatebooking",payload);
        //         window.location = "http://linkroom.showcalendar.lifelink.co.th/";
        //     }
        //     else if(response.data.status_code == 401){
        //         commit("alaert",response.data.status)
        //     }
        //   })
    },
    unavailable({commit}){
        let payload = new FormData()
            payload.append('room_email',response.data.data)
            payload.append('date',response.data.data)
            const headers = {
                'Authorization' : state.user.token
            }
        axios.post(state.user.url+'/linkroom/api/v1/unavailabledialog',payload, {
            headers: headers
        })
        .then(response => {
            if(response.data.status_code == 200){
                commit('setunavailable',response.data.data)
                console.log(response.data.data)

            }
            else if(response.data.status_code == 401){
                commit("alaert",response.data.status)
            }
        })
    },
    selectmult({commit}){
        commit("setmult");
    },
    settokenbooking({commit},payload){
        // console.log(payload.get('token'))
        commit("settoken",payload.get('token'));
        commit("setemail",payload);
    },
    setbooking({commit}){
        let payload = null
        commit("setstatebooking",payload);
    },
    
    booking({commit},payload){
        const headers = {
            'Authorization' : state.user.token
        }
        // con
        axios.post(state.user.url+'/linksroom/api/v1/booking',payload, {
            headers: headers
          })
          .then(response => {
            // console.log(response.data.status_code)
            if(response.data.status_code == 201){

                let payload = true
                // commit("setstatebooking",payload);
                window.location = "http://linkroom.showcalendar.lifelink.co.th/";
            }
            else if(response.data.status_code == 401){
                commit("alaert",response.data.status)
            }
          })
    },
    showlistequipments({commit}){
        const headers = {
            'Authorization' : state.user.token
        }
        axios.post(state.user.url+'/linksroom/api/v1/showlistequipments','', {
            headers: headers
          })
          .then(response => {
            //   console.log(response.data.listequipments)
              if(response.data.status_code == 200){
                commit("countlistequipments",response.data.listequipments)
              }
          })

    },
    listroom({commit},payload){
        let token
        axios.post(state.user.url+'/linksroom/api/v1/token',payload)
          .then(response => {
            token = response.data.data.token
            commit("settoken",token)
            // console.log(response.data.data.token)
            const headers = {
                'Authorization' : state.user.token
            }

            // console.log(state.user.roomemail)
            // axios.post(state.user.url+'/linkroom/api/v1/getroom_name',payload, {
            //     headers: headers
            //   })

            axios.post(state.user.url+'/linksroom/api/v1/listbookingroom',payload, {
                headers: headers
              })
              .then(response => {
                //   console.log(response.data.showroom)
                if(response.data.status_code == 200){
                    // console.log(response.data.listroom.filter(roomname => roomname.email == state.user.roomemail ))
                    console.log(state.user.roomemail)
                    console.log(response.data.listroom)
                    let shoeroom = response.data.listroom.filter(roomname => roomname.email == state.user.roomemail )
                    // console.log(shoeroom.length)
                    // console.log(response.data.showroom )
                    if(response.data.showroom == "true"){


                        if(shoeroom.length != 0){
                            // console.log("show room")
                            commit("setlisrroom",shoeroom)
                            commit("setshowlisrroom",response.data.showroom)
                            commit("setopenconfirmdialog",true)
                        }

                        else if(shoeroom.length == 0){
                            // confirmdialog
                            commit("setshowlisrroom",false)
                            commit("alaert","There are no rooms available or the information is incorrect. Please try again")
  
                            commit("setopenunavailabledialog",true)

                            
                            let load = new FormData()

                            load.append('room_email', state.user.roomemail )
                            load.append('date', payload.get('startdate'))
                            axios.post(state.user.url+'/linkroom/api/v1/unavailabledialog',load, {
                                
                                headers: headers
                            })
                            .then(response => {
                                commit("setshowunavailable",response.data.data)
                                commit("setnavailableroom",response.data.room)
                            })
                            }

                    }
                    else if(response.data.showroom == "false"){
                        commit("setshowlisrroom",false)
                        commit("alaert","There are no rooms available or the information is incorrect. Please try again")
                        commit("setopenunavailabledialog",true)


                        let load = new FormData()

                        load.append('room_email', state.user.roomemail )
                        load.append('date', payload.get('startdate'))
                        axios.post(state.user.url+'/linkroom/api/v1/unavailabledialog',load, {
                            
                            headers: headers
                          })
                          .then(response => {
                            commit("setshowunavailable",response.data.data)
                            commit("setnavailableroom",response.data.room)
                            // console.log(response)
                            // console.log(response.data.data)
                          })
                    }
                }
              })
          })
        

    },
    getSchedule({commit},payload){
        const headers = {
            'Authorization' : state.user.token
        }
        // console.log(state.user.token)
        axios.post(state.user.url+'/linksroom/api/v1/getSchedule',payload, {
            headers: headers
          })
        .then(response => {
            // console.log(response)
            if(response.status == 200){
                if(response.data.status_code == 200){
                    commit("setschedulelist",response.data.data);
                }
            }
            else if(response.status == 401){
                let payload = new FormData()
                payload.append('email', state.user.email)
                axios.post(state.user.url+'/linksroom/api/v1/token',payload)
                .then(response => {
                    if(response.data.status_code == 201){
                        commit("settoken",response.data.data.token);
                    }
                    axios.post(state.user.url+'/linksroom/api/v1/getSchedule',payload, {
                        headers: headers
                      }).then(response => {
                          if(response.status == 200){
                            if(response.data.status_code == 200){
                                commit("setschedulelist",response.data.data);
                            }
                        }
                      }
                      )
                  })
            }
        })
    },
    borrowequipments({commit},payload){
        const headers = {
            'Authorization' : state.user.token
        }
        // console.log(state.user.token)
        axios.post(state.user.url+'/linksroom/api/v1/borrowequipments',payload, {
            headers: headers
          })
        .then(response => {
            // console.log(response)
            if(response.status == 200){
                actions.showlistequipments({commit})
                commit("setstateequipments",true)
            }
        })

    },
    getmoreequipment({commit},payload){
        axios.post(state.user.url+'/linksroom/api/v1/getmoreequipment',payload)
        .then(response => {
            // console.log(response)
            if(response.status == 200)
                commit("setshowmorequipments",response.data.data)
        })

    },
    showroom({commit},schedule){
        // console.log(schedule[0].name)
        // console.log(schedule[0].date_time_use)
        // console.log(schedule[0].date_time_end)
        commit("setshowroom",schedule);
    },
    clearshowroom({commit}){
        commit("clearshowroom");
    },
    login({commit},payload){
      axios.post(state.user.url+'/linkroom/api/v1/login',payload)
        .then(response => {
            // console.log(response.data)
            if(response.status == 200){
            //   console.log(response)
            //   console.log(response.data.data.token)
              commit("settoken",response.data.data.token);
              commit("setemail",payload)

            //   if(response.data.status.status_code === 200){
            //     commit("setuserprofile",response.data.data.userdetail);
            //     commit("settoken",response.data.data.token);
            //     commit("setwating",false);
            //   }
            //   // commit("setsnackbar",response.data.data.text);
            //   if(response.data.status.status_code === 401 ){
            //     commit("setwating",false);
            //     commit("setsnackbartext",response.data.status);
            //     commit("setsnackbar",true);
            //   }
            }
        })
    },
    // logout({commit}){
    //     commit("setStatusLogout");
    // },
    // menu({commit},menu){
    //     // console.log(menu)
    //     commit("setmenu",menu);
    // },
    // menusubject({commit},menu){
    //     // console.log(menu)
    //     commit("setmenusubject",menu);
    // },
    // createpassword({commit},payload){
    //   commit("setwating",true);
    //   axios.post(state.user.url+'/createpassword',payload)
    //     .then(response => {
    //         // console.log(response.data)
    //         if(response.status == 200){
    //           commit("setwating",false);
              
    //           commit("setsnackbartext",response.data.data);
    //           commit("setsnackbar",response.data.data.text);
    //           commit("setsnackbar",true);
    //         }
    //     })
    // }
    getuser({commit},payload){
        // console.log(payload)
    }
}

const mutations = {
    setimg(state,payload){
        state.user.logo = payload.logo_company
        state.user.room = payload.room_img
    },
    countlistequipments(state,payload){
        state.user.countlistequipments[0].maxcount = payload[0][0].count
        if(state.user.countlistequipments[0].maxcount != "0"){
            state.user.countlistequipments[0].status = true
        }
        else if(state.user.countlistequipments[0].maxcount == "0"){
            state.user.countlistequipments[0].status = false
        }
        state.user.countlistequipments[1].maxcount = payload[1][0].count
        if(state.user.countlistequipments[1].maxcount != "0"){
            state.user.countlistequipments[1].status = true
        }
        else if(state.user.countlistequipments[1].maxcount == "0"){
            state.user.countlistequipments[1].status = false
        }
        state.user.countlistequipments[2].maxcount = payload[2][0].count
        if(state.user.countlistequipments[2].maxcount != "0"){
            state.user.countlistequipments[2].status = true
        }
        else if(state.user.countlistequipments[2].maxcount == "0"){
            state.user.countlistequipments[2].status = false
        }
        state.user.countlistequipments[3].maxcount = payload[3][0].count
        if(state.user.countlistequipments[3].maxcount != "0"){
            state.user.countlistequipments[3].status = true
        }
        else if(state.user.countlistequipments[3].maxcount == "0"){
            state.user.countlistequipments[3].status = false
        }
        state.user.countlistequipments[4].maxcount = payload[4][0].count
        if(state.user.countlistequipments[4].maxcount != "0"){
            state.user.countlistequipments[4].status = true
        }
        else if(state.user.countlistequipments[4].maxcount == "0"){
            state.user.countlistequipments[4].status = false
        }
        state.user.countlistequipments[5].maxcount = payload[5][0].count
        if(state.user.countlistequipments[5].maxcount != "0"){
            state.user.countlistequipments[5].status = true
        }
        else if(state.user.countlistequipments[5].maxcount == "0"){
            state.user.countlistequipments[5].status = false
        }
        
    },
    setunavailable(state,searchroom){
        state.user.unavailable = searchroom
        // console.log(state.user.unavailable)

    },
    setopenconfirmdialog(state,status){
        state.user.confirmdialog = status
    },
    setopenunavailabledialog(state,status){
        state.user.unavailabledialog = status 
        // console.log(state.user.unavailabledialog)
    },
    alaert(state,text){
        // console.log('banana')
        state.user.alaert_text = text
        state.user.alaert = true
    },
    setcountlisrroom(state,count){
        state.user.countlistroom = count-1
    },
    setlisrroom(state,listroom){
        state.user.listroom = listroom
    },
    setshowlisrroom(state,status){
        state.user.showlistbookingroom = status
        // console.log("test listroom")
        // console.log(status)
        // console.log(state.user.showlistbookingroom)
    },
    setshowunavailable(state,item){
        state.user.unavailable = item
    },
    setnavailableroom(state,item){
        // console.log(item.email)
        // console.log(item.room_id)
        // console.log(item.name)
        state.user.listroom = []
        var data = {
            'name' : item.name,
            'email' : item.email,
            'room_id' : item.room_id,
        }
        state.user.listroom.push(data)
        console.log(state.user.listroom)
        // state.user.listroom[0]['name'] = item.name
        // store.state.user.listroom[0]['email'] = item.email
        // store.state.user.listroom[0]['room_id'] = item.room_id

        // state.user.unavailable = item
    },
    setstateequipments(state,payload){
        // console.log(state.user.stateequipments )
        state.user.stateequipments = payload
        // console.log(state.user.stateequipments )
    },
    setstatebooking(state,payload){
        state.user.statebooking = payload
    },
    setmult(state){
        state.user.mult = !state.user.mult
    },
    setschedulelist(state,schedulelist){
        state.user.schedulelist = schedulelist
    },
    setshowroom(state,schedule){
        // console.log(schedule)
        state.user.showbooking = schedule[0].booking_id
        state.user.meetingroomname = schedule[0].name
        state.user.meetingroomtitle = schedule[0].topic
        // console.log(schedule[0].date_time_use.substring(11, 13))
        // console.log(schedule[0].date_time_end.substring(11, 13))
        state.user.timeusermeetingtroom.push(schedule[0].date_time_use.substring(11, 13))
        state.user.timeusermeetingtroom.push(schedule[0].date_time_end.substring(11, 13))
        state.user.listequipments = schedule[0].equipments
        // console.log(state.user.timeusermeetingtroom)

    },
    clearshowroom(state){
        state.user.meetingroomname = null
        // console.log(schedule[0].date_time_use.substring(11, 13))
        // console.log(schedule[0].date_time_end.substring(11, 13))
        state.user.timeusermeetingtroom = []
    },
    setemail(state,payload){
        state.user.email = payload.get('email')
        
        // state.user.email = 
    },
    //   setload (state){
    //     console.log(state.user.loading)
    //     state.user.loading = !state.user.loading
    //     console.log(state.user.loading)
    //   },
      // setmenu (state,menu){
      //   state.user.menu = menu
      // },
      // setmenusubject (state,menu){
      //   state.user.subject_menu = menu
      // },
      // setwating (state,status){
      //   state.user.waiting = status
      // },
      // setsnackbartext (state,text){
      //   // console.log(text)
      //   state.user.returnapitext = text.text
      //   state.user.returnapicode = text.status_code
      // },
      // setsnackbar (state,status){
      //   state.user.returnapistatus = status
      // },
      settoken (state,token){
        state.user.token = token
        // console.log(state.user.token)
        // console.log(state.user.token)
      },
      setshowmorequipments(state,listmore){
        state.user.showmorequipments = listmore
      }
      // setuserprofile (state,data){
      //   console.log(data)
      //   state.user.code = data.id
      //   state.user.study_year = data.grade
      //   state.user.tel = data.phone_number
      //   state.user.major_year = data.course_year
      //   state.user.email = data.email
      //   state.user.study_results = data.study_results
      //   // state.user.
      //   console.log(state.user.data['thai'].firstname)

      //   state.user.data['thai'].firstname = data.firstname_thai
      //   state.user.data['thai'].lastname = data.lastname_thia
      //   state.user.data['thai'].faculty = data.faculty_name
      //   state.user.data['thai'].major = data.field_name
      //   state.user.data['thai'].status = data.status_name
      //   if(data.advisor.advisor_firstname_thai !== undefined ){
      //     state.user.data['thai'].advisor = data.advisor.advisor_firstname_thai+" "+data.advisor.advisor_lastname_thai
      //   }
      //   else if(data.advisor.advisor_firstname_thai === undefined ){
      //     state.user.data['thai'].advisor = '-'
      //   }
      //   state.user.data['eng'].firstname = data.firstname_eng
      //   state.user.data['eng'].lastname = data.lastname_eng
      // }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,    
}