<template>
<v-container style="padding-top:10%;height: 100%">
    <v-speed-dial style="position: absolute; padding-top:8%;padding-right:2%" :top="true" :right="true">
        <template v-slot:activator>
            <v-icon color="grey" medium @click="menu('Home')">
                mdi-home-circle-outline
            </v-icon>
        </template>
    </v-speed-dial>
    <v-row justify='start' style="padding-left:3%;padding-right:3%">
        <v-card-title class="font-weight-black">
            Calendar
        </v-card-title>
    </v-row>
    <v-row style="padding-top:0%;padding-left:3%;padding-right:3%" justify='center' outline>
        <!-- <v-col cols="12">
            <v-card width="100%" height="100%" outlined style="border-radius: 15px;" @click="menu('Showroom')">
                <v-row>
                    <v-col cols="12" style="padding-left:10%">
                        <v-card-title style="padding-left:0px;padding-bottom: 2px;">
                            Meeting Dev
                        </v-card-title>
                        <v-card-text style="padding: 0px">
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">location :</b> Floor 31</p>
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">Start : </b> 8/7/20 16:00 <b style="padding-left:5%" class="primary--text">End : </b> 8/7/20 17:00</p>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-col> -->
        <!-- <v-col cols="12" style="padding-top:0%">
            <v-card width="100%" height="100%" outlined style="border-radius: 15px;" @click="menu('Showroom')">
                <v-row>
                    <v-col cols="12" style="padding-left:10%">
                        <v-card-title style="padding-left:0px;padding-bottom: 2px;">
                            HR
                        </v-card-title>
                        <v-card-text style="padding: 0px">
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">location :</b> Floor 31</p>
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">Start : </b> 10/7/20 16:00 <b style="padding-left:5%" class="primary--text">End : </b> 10/7/20 17:00</p>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-col> -->
        <!-- <v-col cols="12" style="padding-top:0%">
            <v-card width="100%" height="100%" outlined style="border-radius: 15px;" @click="menu('Showroom')">
                <v-row>
                    <v-col cols="12" style="padding-left:10%">
                        <v-card-title style="padding-left:0px;padding-bottom: 2px;">
                            Update Products
                        </v-card-title>
                        <v-card-text style="padding: 0px">
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">location :</b> Floor 31</p>
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">Start : </b> 15/7/20 09:00 <b style="padding-left:5%" class="primary--text">End : </b> 15/7/20 17:00</p>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-col> -->
        <v-col cols="12" style="padding-top:0%" v-for="schedule in  user.schedulelist" :key="schedule.id" >
            <v-card width="100%" height="100%" outlined style="border-radius: 15px;" @click="showroom(schedule)">
                <v-row>
                    <v-col cols="12" style="padding-left:10%">
                        <v-card-title style="padding-left:0px;padding-bottom: 2px;">
                            {{schedule[0].topic}}
                        </v-card-title>
                        <v-card-text style="padding: 0px">
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">location :</b> {{schedule[0].name}}</p>
                            <p style="font-size:12px;margin: 2px;" class="grey--text line"><b class="primary--text">Start : </b> {{schedule[0].date_time_use}} <b style="padding-left:5%" class="primary--text">End : </b> {{schedule[0].date_time_end}}</p>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

    </v-row>

</v-container>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'

export default {
    data: vm => ({
        startdate: new Date().toISOString().substr(0, 10),
        menustartdate: false,
        enddate: new Date().toISOString().substr(0, 10),
        menuenddate: false,

        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        enddateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),

        startime: null,
        menustarttime: false,
        endtime: null,
        menuendtime: false,
    }),
    components: {},
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
        computedDateFormatted() {
            return this.formatDate(this.startdate)
        },
    },
    watch: {
        startdate(val) {
            this.dateFormatted = this.formatDate(this.startdate)
        },
        enddate(val) {
            this.enddateFormatted = this.formatDate(this.enddate)
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        menu(menu) {
            this.$router.push({
                name: menu
            });
        },
        getlistroom() {
            // console.log("geeting")
            var today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            var time = ("0" +today.getHours()).slice(-2) + ":" + ("0" +today.getMinutes()).slice(-2) + ":" + today.getSeconds();
            var dateTime = date + ' ' + time;
            var startdate = date
            var starttime = time
            var enddate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate()+7);
            var endtime = "23:59:59"
            // console.log(startdate)
            // console.log(starttime)
            // console.log(enddate)
            // console.log(endtime)
            // var email = "chayanonb@wtc.co.th"

            // this.$store.state.users.user.email 

            let payload = new FormData()
            payload.append('email', this.$store.state.users.user.email )
            payload.append('startdate', startdate)
            payload.append('starttime', starttime)
            payload.append('enddate', enddate)
            payload.append('endtime', endtime)
            this.$store.dispatch("users/getSchedule", payload)
            this.$store.dispatch("users/clearshowroom")
            // this.$router.push({
            //     name: 'Calender'
            // });
        },
        showroom(schedule) {
           this.$store.dispatch("users/showroom", schedule) 
           this.$router.push({
                name: 'Showroom'
            });
        }

    },
    created() {
        this.$store.state.users.user.showmorequipments == []
        if (this.$store.state.users.user.token == null) {
            this.$router.push({
                name: 'Login'
            });
        }
        this.getlistroom()
        this.$store.dispatch("users/setbooking")
    }
}
</script>

<style>
.box {
    background: rgb(134, 149, 154);
    background: linear-gradient(0deg, rgba(134, 149, 154, 1) 0%, rgba(43, 49, 75, 1) 100%);

}
</style>
