<template>
<v-app>
    <v-main>
        <!-- <Home/> -->
        <router-view></router-view>
    </v-main>
    <!-- <v-footer padless>
        <v-col class="text-end white" cols="12">
            <v-btn text @click="edit_language('thai')">ไทย</v-btn> | <v-btn text @click="edit_language('eng')">Eng</v-btn>
        </v-col>
    </v-footer> -->
</v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import Home from './views/Home'

export default {
    name: 'App',

    components: {
        HelloWorld,
        Home
    },

    data: () => ({
        //
    }),
    methods: {
        edit_language(language) {
            this.$store.state.users.user.language = language
        }
    }
};
</script>
<style scoped>
@font-face {
  font-family: "CPF-Imm-Sook-Regular";
  src: local("CPF-Imm-Sook-Regular"),
   url(./fonts/CPF-Imm-Sook-Regular.ttf) format("truetype");
}
</style>