<template>
<v-container fluid style="height: 100vh;">
    <v-dialog v-model="fromdialog" persistent max-width="500">
        <v-card>
            <v-card-title>
                <!-- โปรดกรอกข้อมูลให้ครบถ้วน -->
                <v-card-text>
                    <p class="text-center" style="font-size: 42px;">
                        โปรดกรอกข้อมูลให้ครบถ้วน
                    </p>
                </v-card-text>
                <v-card-text>
                    <p class="text-center">
                        <v-btn color="primary" style="font-size: 36px;" @click="fromdialog = false">
                            ตกลง
                        </v-btn>
                    </p>
                </v-card-text>

            </v-card-title>
        </v-card>
    </v-dialog>
    <v-overlay :z-index="1" style="top:-50vh" :absolute="absolute" :opacity="opacity" :value="overlay2">
        <v-card width="100vh" height="8vh">
            <v-text-field v-model="title" style=" height: 8vh; font-size: 20px;" dark background-color="white" color="black" height="8vh" filled :label='user.menu[user.language]["titletopic"]'></v-text-field>
            <!-- <v-text-field v-model="title" height="8vh" style=" height: 8vh; font-size: 20px;" background-color="white" filled dense :label='user.menu[user.language]["titletopic"]'></v-text-field> -->
            <div style="padding-top:2vh">
                <v-btn @click="overlay2 = !overlay2" color="white" class="black--text">confirm</v-btn>
            </div>
        </v-card>
    </v-overlay>

    <v-overlay :z-index="1" style="top:-50vh" :absolute="absolute" :opacity="opacity" :value="overlay">
        <v-card width="100vh" height="8vh">
            <v-text-field v-model="email" style=" height: 8vh; font-size: 20px;" dark background-color="white" color="black" height="8vh" filled :label='user.menu[user.language]["lableemail"]'></v-text-field>
            <div style="padding-top:2vh">
                <v-btn @click="overlay = !overlay" color="white" class="black--text">confirm</v-btn>
            </div>
        </v-card>
    </v-overlay>

    <v-overlay :z-index="1" style="top:-50vh" :absolute="absolute" :opacity="opacity" :value="overlay3">
        <v-card width="100vh" height="8vh">
            <v-text-field v-model="outsider" style=" height: 8vh; font-size: 20px;" dark background-color="white" color="black" height="8vh" filled></v-text-field>
            <div style="padding-top:2vh">
                <v-btn @click="overlay3 = !overlay3" color="white" class="black--text">confirm</v-btn>
            </div>
        </v-card>
    </v-overlay>
    <!-- <v-app-bar height="200vh" class="cpbox" dark>

        <v-toolbar-title style="padding-left:2vh">

            <v-row wrap>
                <v-col justify='center' align="center" cols="3">
                    <v-img contain height="150px" width="150px" :src="require('../assets/cpicon.png')" />
                </v-col>
                <v-col cols="8">
                    <v-row wrap>
                        <v-col cols="12" style="padding:0;padding-top:5vh;padding-left:2vh" class="display-2 font-weight-bold">Room CPFIT16C</v-col>
                        <v-col cols="12" style="padding:0;">
                            <v-card-text style="padding:0;padding-left:2vh" class="display-1 font-weight-light">
                                {{user.menu[user.language]["booking"]}}
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-toolbar-title>

    </v-app-bar> -->
    <!-- <v-row wrap style="padding-left:3%;padding-right:3%">
        <v-col cols="md-7">
            <v-card-title class="black--text font-weight-black">
                {{user.menu[user.language]["booking"]}}
            </v-card-title>
        </v-col>
        <v-col cols="md-5" style="padding-bottom:0">
            <v-card-title style="padding-bottom:0" class="black--text font-weight-black">
                <v-text-field v-model="email" label="useremail" outlined rounded dense></v-text-field>
            </v-card-title>
        </v-col>
    </v-row> -->
    <v-row justify="center">
        <v-col cols="6" class="bg_green" fluid style="height: 100vh;">
            <v-row justify="center" class="mt-10">
                <v-col justify='center' align="center" cols="5" class="mt-n4">
                    <!-- <v-img contain height="120px" width="120px" :src="require('../assets/logo_linkroom01.png')" /> -->
                    <v-img contain height="100%" width="70%" :src="user.logo" />
                </v-col>
                <v-col cols="7" style="padding-top:5vh;padding-left:0vh">
                    <v-card-text class=" white--text " style="padding-left:0vh; font-size: 90px; padding-top: 4vh;">
                        {{user.menu[user.language]["booking"]}}
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="9" style="padding:0;" class="mt-8">
                    <v-card-title style="color:white; font-size:40px">{{user.menu[user.language]["meeting_topic"]}}<b class="red--text" v-if="alert">*</b></v-card-title>
                    <v-text-field @click="overlay2 = !overlay2" v-model="title" height="6vh" style=" height: 6vh; font-size: 20px;" background-color="white" filled dense :label='user.menu[user.language]["titletopic"]'></v-text-field>
                    <!-- <v-card-text style="color:red; font-size: 25px">*โปรดกรอกข้อมูลให้ครบถ้วน</v-card-text> -->
                    <v-card-title style="color:white;font-size:40px;">{{user.menu[user.language]["useremail"]}}<b class="red--text  " v-if="alert">*</b></v-card-title>
                    <v-text-field @click="overlay = !overlay" v-model="email" style=" height: 6vh; font-size: 20px;" height="6vh" background-color="white" filled dense :label='user.menu[user.language]["lableemail"]'></v-text-field>
                    <!-- <v-card-text style="color:red; font-size: 25px">*โปรดกรอกข้อมูลให้ครบถ้วน</v-card-text> -->

                    <!-- <v-card-text style="color:red; font-size:16px">
                        <v-switch v-model="Onlinemeeting" color="white" :label="`Online meeting (Teams meeting)`">
                            <template v-slot:label>
                                <span class="white--text font-weight-black" style="font-size: 28px">Online meeting (Teams meeting)</span>
                            </template>
                        </v-switch>
                    </v-card-text> -->

                    <!-- <v-card-title class="font-weight-black" style="color:white; font-size: 26px">{{user.menu[user.language]["meeting_type"]}}<b class="red--text" v-if="alert">*</b></v-card-title> -->

                    <!-- <v-select style="padding-top: 0vh;height:5vh" :items="meeting_type_item" v-model="meeting_type" background-color="white" color="white"></v-select> -->
                    <v-row wrap>
                        <v-col cols=4>
                            <v-card-title class="font-weight-black" style="color:white; font-size: 25px;padding-left:0vh">{{user.menu[user.language]["meeting_type"]}}<b class="red--text" v-if="alert">*</b></v-card-title>
                        </v-col>
                        <v-col cols=8>
                            <v-row wrap>

                                <!-- <v-col cols="12" style="padding-top:3vh">
                                    <v-row wrap>
                                        <v-col cols="1" style="padding:0vh">
                                            <v-checkbox class="white--text font-weight-black" v-model="Onlinemeeting" color="white" value="true"></v-checkbox>
                                        </v-col>
                                        <v-col cols="11" style="padding-top:1vh;padding-left:0vh;padding-bottom: 0vh;">
                                            <v-card-text class="white--text font-weight-black" style="font-size:25px">Internal Meeting</v-card-text>
                                        </v-col>
                                    </v-row>

                                </v-col>

                                <v-col cols="12" style="padding-top:0vh">
                                    <v-row wrap>
                                        <v-col cols="1" style="padding:0vh">
                                            <v-checkbox class="white--text font-weight-black" v-model="Onlinemeeting" color="white" value="true"></v-checkbox>
                                        </v-col>
                                        <v-col cols="11" style="padding:0vh;">
                                            <v-card-text class="white--text font-weight-black" style="font-size:25px">Meeting whit outsider  <v-text-field  v-model="title" height="5vh" style=" height: 5vh; font-size: 20px;" background-color="white" filled dense ></v-text-field>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>

                                </v-col> -->

                                <v-container fluid style="padding-left:0vh" class="mt-4">
                                    <v-radio-group v-model="meeting_type">
                                        <v-radio value="internal meeting" color="white">
                                            <template v-slot:label>
                                                <div class="white--text font-weight-black" color="white" style="font-size:20px">Internal Meeting</div>
                                            </template>
                                        </v-radio>
                                        <v-radio value="Meeting whit outsider" color="white">
                                            <template v-slot:label>
                                                <!-- <div class="white--text font-weight-black" color="white">Meeting whit outsider  </div><b/>
                                                <v-text-field  v-model="title" height="5vh" style=" height: 5vh; font-size: 20px;" background-color="white" filled dense ></v-text-field> -->
                                                <v-row wrap>
                                                    <v-col cols=6>
                                                        <div class="white--text font-weight-black" color="white" style="font-size:20px">Meeting with outsider </div>
                                                    </v-col>
                                                    <v-col cols=6>
                                                        <v-text-field @click="overlay3 = !overlay3" v-model="outsider" v-if="meeting_type=='Meeting whit outsider'" height="2vh" style=" height: 2vh; font-size: 20px;padding:0vh" background-color="white" filled dense></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <!-- <v-card-text class="white--text font-weight-black" style="font-size:25px">Meeting whit outsider  <v-text-field  v-model="title" height="5vh" style=" height: 5vh; font-size: 20px;" background-color="white" filled dense ></v-text-field> -->
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </v-container>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-card-text style="padding-top:1vh">
                        <!-- <v-checkbox class="white--text font-weight-black"  v-model="ex4" color="white" value="true" hide-details>Internal meeting</v-checkbox> -->
                        <v-row wrap style="padding:0vh">
                            <v-col cols="1" style="padding-top:0vh;padding-bottom: 0vh;">
                                <v-checkbox class="white--text font-weight-black" v-model="Onlinemeeting" color="white" value="true"></v-checkbox>
                            </v-col>
                            <v-col cols="10" style="padding-top:1vh;padding-left:0vh;padding-bottom: 0vh;">
                                <v-card-text class="white--text font-weight-black" style="font-size:28px">Online meeting (Teams meeting)</v-card-text>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-row justify='center' style="padding-top:0vh; padding-bottom:10vh">
                        <v-col cols="6">
                            <v-btn depressed class=" primary--text " width="100%" height="50" style="border-radius: 10px;font-size: 25px;" :disabled="checkbtntime" @click="getlistroom">
                                {{user.menu[user.language]["search"]}}
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn width="100%" height="50" class=" dark--text " style="border-radius: 10px;font-size: 25px;" @click="clear" color="grey" depressed>
                                {{user.menu[user.language]["cancel"]}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </v-col>
        <v-col cols="6" style="padding-top:5vh">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateFormatted" v-bind="attrs" v-on="on"></v-text-field>
            </template>

            <v-date-picker style="font-size: 50px; height: 85%;font-weight: bold; " full-width :min=today v-model="startdate" no-title scrollable color="#8fc748" @input="menustartdate = false">
            </v-date-picker>
            <v-row justify="center" style="padding-left: 5vh;padding-top:2vh">
                <v-card-title style="font-size:24px"><b>Start : </b></v-card-title>
                <v-col cols="4" style="padding-top:1vh">
                    <v-select v-model="starttime" height="5vh" style="border-radius: 16px; font-size: 18px;" :items="states" outlined rounded menu-props="auto" label="Time" hide-details single-line></v-select>
                </v-col>
                <v-card-title style="font-size:24px"><b>End : </b></v-card-title>
                <v-col cols="4" style=" padding-top:1vh">
                    <v-select height="3vh" v-model="endtime" :items="states1" style="border-radius: 16px;  font-size: 18px;" outlined rounded menu-props="auto" label="Time" hide-details single-line></v-select>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <template>
        <v-row justify="center">
            <v-dialog v-model="user.confirmdialog" persistent max-width="1000px">
                <v-card v-if="user.showlistbookingroom">
                    <v-card-text>
                        <v-row justify="center" style="padding:6vh">

                            <v-col cols="3 " style="font-size: 24px;padding:2vh;padding-left:1vh" class="font-weight-black">
                                <v-row wrap>
                                    <v-col cols="10" style="padding:2vh">
                                        User Email
                                    </v-col>
                                    <v-col cols="1">
                                        :
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="9">
                                <v-card flat color="grey lighten-2" style="padding-left:15px; font-size:18px ; padding:2vh;height: 6vh;" v-model="email">
                                    {{email}}
                                </v-card>
                            </v-col>

                            <v-col cols="3 " style="font-size: 24px;padding:2vh;padding-left:1vh" class="font-weight-black">
                                <v-row wrap>
                                    <v-col cols="10" style="padding:2vh">
                                        Topic
                                    </v-col>
                                    <v-col cols="1">
                                        :
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="9">
                                <v-card flat color="grey lighten-2" style="padding-left:15px; font-size:18px ; padding:2vh;height: 6vh;" v-model="email">
                                    {{title}}
                                </v-card>
                            </v-col>

                            <!-- <v-col cols="3 " style="font-size: 16px;">Calendar</v-col>
                            <v-col cols="1">:</v-col>
                            <v-col cols="3">
                                <v-card flat color="pr" style="padding-left:15px;padding:2vh">
                                    {{startdate}}
                                </v-card>
                            </v-col> -->

                            <v-col cols="3 " style="font-size: 24px;padding:2vh" class="font-weight-black">
                                <v-row wrap>
                                    <v-col cols="10" style="padding:2vh;padding-left:1vh">
                                        Calendar
                                    </v-col>
                                    <v-col cols="1">
                                        :
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="3">
                                <v-card flat color="grey lighten-2" style="padding-left:15px; font-size:18px ; padding:2vh;height: 6vh;" v-model="email">
                                    {{startdate}}
                                </v-card>
                            </v-col>
                            <v-col cols="2 " style="font-size: 24px;padding:2vh" class="font-weight-black">
                                <v-row wrap>
                                    <v-col cols="10" style="padding:2vh;padding-left:1vh">
                                        Time :
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4">
                                <v-card flat color="grey lighten-2" style="padding-left:15px; font-size:18px ; padding:2vh;height: 6vh;" v-model="email">
                                    {{starttime}} - {{endtime}}
                                </v-card>
                            </v-col>

                            <!-- <v-col cols="2 " class="text-center" style="font-size: 18px;">Time :</v-col>
                            <v-col cols="3">
                                <v-card flat color="pr" style="padding-left:15px; padding:2vh">
                                    {{starttime}} - {{endtime}}
                                </v-card>
                            </v-col> -->
                            <!-- <v-col cols="8">
                                <v-row wrap>
                                    <v-col cols="4" style="font-size: 16px;">ห้องประชุม</v-col>
                                    <v-col cols="2" style="padding-left:4vh">:</v-col>
                                    <v-col cols="6">
                                        {{user.roomemail}}
                                    </v-col>
                                </v-row>
                                <v-row wrap>
                                    <v-col cols="4" style="font-size: 16px;">Online meeting</v-col>
                                    <v-col cols="2" style="padding-left:4vh">:</v-col>
                                    <v-col cols="6" style="padding-top: 0vh;">
                                        <v-switch disabled v-model="Onlinemeeting" color="success" style="margin:0vh" :label="`Online meeting (Teams meeting)`">
                                            <template v-slot:label>
                                                <span class="white--text font-weight-black">Online meeting (Teams meeting)</span>
                                            </template>
                                        </v-switch>
                                    </v-col>
                                </v-row>

                            </v-col> -->

                            <v-col cols="8">
                                <v-row wrap>
                                    <v-col cols="5" style="font-size: 24px;padding:2vh;padding-left:1vh" class="font-weight-black">
                                        <v-row wrap>
                                            <v-col cols="9" style="padding:2vh">
                                                ห้องประชุม
                                            </v-col>
                                            <v-col cols="1">
                                                :
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!-- <v-col cols="4" style="font-size: 24px;" class="font-weight-black">ห้องประชุม</v-col> -->
                                    <!-- <v-col cols="2" style="padding-left:4vh;">:</v-col> -->
                                    <v-col cols="7" style="font-size:24px ;padding:2vh">
                                        {{user.roomemail}}
                                    </v-col>
                                </v-row>

                                <v-row wrap>
                                    <v-col cols="5" style="font-size: 24px;padding:2vh" class="font-weight-black">
                                        <v-row wrap>
                                            <v-col cols="9" style="padding:2vh;padding-left:1vh">
                                                Online
                                            </v-col>
                                            <v-col cols="1">
                                                :
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!-- <v-col cols="4" style="font-size: 24px;" class="font-weight-black">ห้องประชุม</v-col> -->
                                    <!-- <v-col cols="2" style="padding-left:4vh;">:</v-col> -->
                                    <v-col cols="7" style="font-size:24px">
                                        <!-- {{Onlinemeeting}} -->
                                        <v-icon large v-if="Onlinemeeting != 'true'" color="red">
                                            mdi-close-circle-outline
                                        </v-icon>

                                        <v-icon large v-if="Onlinemeeting == 'true'" color="green">
                                            mdi-checkbox-marked-circle-outline
                                        </v-icon>
                                    </v-col>
                                </v-row>

                            </v-col>

                            <v-col cols="4">
                                <v-row wrap>
                                    <v-col cols="12">
                                        <!-- <v-img width="170px" :src="require('../assets/room_img.jpg')" /> -->
                                        <v-img contain height="100%" width="100%" :src="user.room" />
                                    </v-col>
                                </v-row>

                            </v-col>

                            <v-col cols="3 " style="font-size: 24px;padding:2vh;padding-left:1vh" class="font-weight-black">
                                <v-row wrap>
                                    <v-col cols="10" style="padding:2vh">
                                        Meeting type
                                    </v-col>
                                    <v-col cols="1">
                                        :
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="9">
                                <v-card flat color="grey lighten-2" style="padding-left:15px; font-size:18px ; padding:2vh;height: 6vh;" v-model="email">
                                    <v-card-text style="padding-left:0vh;font-size:24px;padding-top:0vh" class="primary--text" v-if="meeting_type == 'internal meeting'">{{meeting_type}}</v-card-text>
                                    <v-card-text style="padding-left:0vh;font-size:24px;padding-top:0vh" class="red--text" v-if="meeting_type == 'Meeting whit outsider'">{{meeting_type}}: {{outsider}}</v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="2">

                            </v-col>
                            <v-col cols="4">
                                <v-btn depressed class=" white--text title" width="100%" height="50" style="border-radius: 10px" color="primary" @click="booking">
                                    {{user.menu[user.language]["booking"]}}
                                </v-btn>
                            </v-col>
                            <v-col cols="4">
                                <v-btn width="100%" height="50" class=" dark--text title" style="border-radius: 10px" @click="closedialog" color="grey" depressed>
                                    {{user.menu[user.language]["cancel"]}}
                                </v-btn>
                            </v-col>
                            <v-col cols="2">

                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-snackbar v-model="user.alaert" :timeout="timeout">
                {{ user.alaert_text }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="pink" text v-bind="attrs" @click="closesnackbar">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-row>
    </template>

    <template>
        <v-row justify="center">
            <v-dialog v-model="user.unavailabledialog" persistent fullscreen>
                <v-card>

                    <v-toolbar color="bg_green" height="70">
                        <v-flex xs9 style="padding-left:3px;">
                            <v-img style="padding-top:2%" contain id="img" width="20%" :src="require('../assets/logo_linkroom.png')" />
                        </v-flex>
                    </v-toolbar>
                    <v-row style="padding-left:15vh">
                        <v-card-title style="padding-top:10vh;padding-bottom:5vh;font-size:2.2vw">สถานะการจองห้อง</v-card-title>
                        <v-divider></v-divider>
                    </v-row>
                    <v-row style="padding-left:15vh;padding-right:15vh">
                        <v-card width="100%" class="text-center" flat>
                            <!-- <v-toolbar color="primary">
                                <v-col cols="3" class="white--text text-center">Time</v-col>
                                <v-col cols="2" class="white--text text-center">Status</v-col>
                                <v-col cols="4" class="white--text text-center">Booking</v-col>
                            </v-toolbar> -->
                            <!-- {{user.unavailable}} -->

                            <v-data-table :headers="headers" :items="user.unavailable" :items-per-page="15" class="elevation-1" hide-default-footer>
                                <template v-slot:item.booking="{item}">
                                    <v-chip color="grey" class="white--text" v-if="item.status === 'booking'">จอง</v-chip>
                                    <v-chip color="bg_green" class="white--text" v-if="item.status != 'booking'" @click="unavailable(item)">จอง</v-chip>
                                </template>
                            </v-data-table>

                        </v-card>

                    </v-row>
                    <v-row justify="center" style="padding:5vh">
                        <v-btn height="50" width="50%" class=" dark--text title" style="border-radius: 10px" @click="closeunavailabledialog" color="grey" depressed>
                            {{user.menu[user.language]["cancel"]}}
                        </v-btn>
                    </v-row>

                </v-card>

            </v-dialog>
        </v-row>
    </template>

</v-container>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'

export default {
    data: vm => ({
        checkbtntime: false,
        outsider: '',
        overlay3: false,
        fromdialog: false,
        alert: false,
        absolute: false,
        opacity: 1,
        overlay: false,
        overlay2: false,
        Onlinemeeting: false,
        checktime: false,
        topic: null,
        password: null,
        confirmdialog: false,
        title: null,
        email: null,
        meeting_type_item: ['internal meeting', 'external meeting'],
        meeting_type: null,
        model: 0,
        starttime: '08:00',
        endtime: '08:29',
        timeout: 20000,
        headers: [{
                text: 'Time',
                value: 'time'
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Booking',
                value: 'booking'
            }
        ],
        desserts: [{
                time: '09:00 - 10:00',
                booking_status: 'ว่าง',
                booking: 'จอง'
            },
            {
                time: '10:00 - 11:00',
                booking_status: 'ว่าง',
                booking: 'จอง'
            },
            {
                time: '11:00 - 12:00',
                booking_status: 'ว่าง',
                booking: 'จอง'
            },
            {
                time: '12:00 - 13:00',
                booking_status: 'ว่าง',
                booking: 'จอง'
            },
            {
                time: '13:00 - 14:00',
                booking_status: 'ว่าง',
                booking: 'จอง'
            },
            {
                time: '14:00 - 15:00',
                booking_status: 'ว่าง',
                booking: 'จอง'
            },
            // { time: '15:00 - 16:00', booking_status: 'ว่าง', booking: 'จอง' },
            // { time: '16:00 - 17:00', booking_status: 'ว่าง', booking: 'จอง' },
            // { time: '17:00 - 18:00', booking_status: 'ว่าง', booking: 'จอง' },
            // { time: '18:00 - 19:00', booking_status: 'ไม่ว่าง', booking: 'จอง' },
            // { time: '19:00 - 20:00', booking_status: 'ไม่ว่าง', booking: 'จอง' },
        ],

        states: [{
                text: '07:00',
                value: '07:00'
            },
            {
                text: '07:30',
                value: '07:30'
            },
            {
                text: '08:00',
                value: '08:00'
            },
            {
                text: '08:30',
                value: '08:30'
            },

            {
                text: '09:00',
                value: '09:00'
            },
            {
                text: '09:30',
                value: '09:30'
            },
            {
                text: '10:00',
                value: '10:00'
            },
            {
                text: '10:30',
                value: '10:30'
            },

            {
                text: '11:00',
                value: '11:00'
            },
            {
                text: '11:30',
                value: '11:30'
            },
            {
                text: '12:00',
                value: '12:00'
            },
            {
                text: '12:30',
                value: '12:30'
            },

            {
                text: '13:00',
                value: '13:00'
            },
            {
                text: '13:30',
                value: '13:30'
            },
            {
                text: '14:00',
                value: '14:00'
            },
            {
                text: '14:30',
                value: '14:30'
            },

            {
                text: '15:00',
                value: '15:00'
            },
            {
                text: '15:30',
                value: '15:30'
            },
            {
                text: '16:00',
                value: '16:00'
            },
            {
                text: '16:30',
                value: '16:30'
            },

            {
                text: '17:00',
                value: '17:00'
            },
            {
                text: '17:30',
                value: '17:30'
            },
            {
                text: '18:00',
                value: '18:00'
            },
            {
                text: '18:30',
                value: '18:30'
            },

            {
                text: '19:00',
                value: '19:00'
            },
            {
                text: '19:30',
                value: '19:30'
            },
            {
                text: '20:00',
                value: '20:00'
            },
            {
                text: '20:30',
                value: '20:30'
            },
        ],
        states1: [{
                text: '07:00',
                value: '06:59'
            },
            {
                text: '07:30',
                value: '07:29'
            },
            {
                text: '08:00',
                value: '07:59'
            },
            {
                text: '08:30',
                value: '08:29'
            },

            {
                text: '09:00',
                value: '08:59'
            },
            {
                text: '09:30',
                value: '09:29'
            },
            {
                text: '10:00',
                value: '09:59'
            },
            {
                text: '10:30',
                value: '10:29'
            },

            {
                text: '11:00',
                value: '10:59'
            },
            {
                text: '11:30',
                value: '11:29'
            },
            {
                text: '12:00',
                value: '11:59'
            },
            {
                text: '12:30',
                value: '12:29'
            },

            {
                text: '13:00',
                value: '12:59'
            },
            {
                text: '13:30',
                value: '13:29'
            },
            {
                text: '14:00',
                value: '13:59'
            },
            {
                text: '14:30',
                value: '14:29'
            },

            {
                text: '15:00',
                value: '14:59'
            },
            {
                text: '15:30',
                value: '15:29'
            },
            {
                text: '16:00',
                value: '15:59'
            },
            {
                text: '16:30',
                value: '16:29'
            },

            {
                text: '17:00',
                value: '16:59'
            },
            {
                text: '17:30',
                value: '17:29'
            },
            {
                text: '18:00',
                value: '17:59'
            },
            {
                text: '18:30',
                value: '18:29'
            },

            {
                text: '19:00',
                value: '18:59'
            },
            {
                text: '19:30',
                value: '19:29'
            },
            {
                text: '20:00',
                value: '19:59'
            },
            {
                text: '20:30',
                value: '20:29'
            },
        ],
        room: [
            '1',
            '2',
            '3',
            '4',
            '5',
        ],
        today: new Date().toISOString().substr(0, 10),
        startdate: new Date().toISOString().substr(0, 10),
        menustartdate: false,
        enddate: new Date().toISOString().substr(0, 10),
        menuenddate: false,

        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        enddateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),

        startime: null,
        menustarttime: false,
        menuendtime: false,

        listroom: false,
        value: [8, 18],
        seasons: [
            '',
            '8.00',
            '9.00',
            '10.00',
            '11.00',
            '12.00',
            '13.00',
            '14.00',
            '15.00',
            '16.00',
            '17.00',
            '18.00',

        ],
        icons: [
            'mdi-snowflake',
            'mdi-leaf',
            'mdi-fire',
            'mdi-water',
            'mdi-snowflake',
            'mdi-leaf',
            'mdi-fire',
            'mdi-water',
            'mdi-snowflake',
            'mdi-leaf',
            'mdi-fire',
            'mdi-water',
        ],
    }),
    components: {},
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
        computedDateFormatted() {
            return this.formatDate(this.startdate)
        },
        formsearch() {
            return (
                (this.title,
                    this.email)
                // &&
                // this.checktime
            );
        },
        formlogin() {
            return (
                this.title
                // this.email 
            );
        },
        formpassword() {
            return (
                this.password
            )
        },
        statebooking() {
            return this.$store.state.users.user.statebooking
        },
    },
    watch: {

        statebooking() {
            // console.log(".....")
            this.$router.push({
                name: 'Calender'
            });
        },

        startdate(val) {
            this.dateFormatted = this.formatDate(this.startdate)
            this.enddate = this.startdate
            this.enddateFormatted = this.formatDate(this.enddate)

            var today = new Date();

            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            var daytoday = yyyy + '-' + mm + '-' + dd;

            // console.log(this.startdate)
            // console.log(daytoday)

            if (this.startdate > daytoday) {
                this.checktime = true
            }
            if (this.startdate == daytoday) {
                var time = today.getHours() + ":" + today.getMinutes()
                if (time < this.starttime) {
                    this.checktime = true
                    // console.log("more")

                } else if (time > this.starttime) {
                    // console.log("less")
                    // this.checktime = false
                    this.checktime = true
                }

            }

        },

        enddate(val) {
            this.enddateFormatted = this.formatDate(this.enddate)
        },
        starttime() {
            var today = new Date();
            var time = today.getHours() + ":" + today.getMinutes()

            // console.log(today.now())

            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            var daytoday = yyyy + '-' + mm + '-' + dd;

            // console.log(this.starttime)

            if (time < this.starttime) {
                this.checktime = true
                // console.log("more")
                // console.log(this.checktime)

            } else if (time > this.starttime) {
                // console.log("less")
                // this.checktime = false
                this.checktime = true
            }

            for (let i = 0; i < 28; i++) {
                // console.log(this.states[i].value)
                if (this.states[i].value == this.starttime) {
                    // console.log(i)
                    if (i < 23) {

                        this.startime = this.states[i]
                        this.endtime = this.states1[i + 4].value
                        // console.log(this.startime)
                        // console.log(this.endtime)
                    } else if (i >= 23) {
                        this.startime = this.states[i]
                        console.log(this.states1[27])
                        this.endtime = this.states1[27].value
                    }
                }
            }
            if (this.startdate > daytoday) {
                this.checktime = true

            }
        },
        endtime() {
            // console.log(this.endtime)
            // console.log(this.startime.value)
            if(this.startime.value > this.endtime){
                for (let i = 0; i < 28; i++) {
                    if (this.states[i].value == this.starttime) {
                    // console.log(i)
                    // this.endtime = this.states1[i+4].value
                    this.checkbtntime = true
                    }
                    
                }
            }
            else{
                this.checkbtntime = false
            }
        },

    },
    methods: {
        closesnackbar() {
            this.$store.state.users.user.alaert = false
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        menu(menu) {
            this.$router.push({
                name: menu
            });
            this.$store.state.users.user.showlistbookingroom = false
        },
        season(val) {
            return this.icons[val]
        },
        getlistroom() {
            //new
            if (this.title != null && this.meeting_type != null && this.email != null) {
                this.confirmdialog = true

                // console.log(this.endtime)

                let payload = new FormData()
                payload.append('email', this.email)
                payload.append('startdate', this.startdate)
                payload.append('starttime', this.starttime + ":00")
                payload.append('enddate', this.enddate)
                payload.append('endtime', this.endtime + ":00")

                this.$store.dispatch("users/listroom", payload)
            } else {
                this.fromdialog = true
                this.alert = true
            }

        },

        checkmember() {
            this.$store.dispatch("users/checkmember")

        },
        unavailable(item) {

            this.starttime = item.time.split(" ")[0]
            this.endtime = item.time.split(" ")[2]

            // console.log(this.endtime)
            this.$store.state.users.user.unavailabledialog = false

            this.$store.state.users.user.confirmdialog = true
            this.$store.state.users.user.showlistbookingroom = true

        },
        booking() {

            this.dialog = false
            let payload = new FormData()
            payload.append('email', this.email)
            // console.log(this.endtime)

            payload.append('subject', this.title)
            payload.append('startdate', this.startdate)
            payload.append('starttime', this.starttime + ":00")
            payload.append('endtime', this.endtime + ":00")
            payload.append('enddate', this.enddate)

            payload.append('lacation_name', this.$store.state.users.user.listroom[this.model]['name'])
            payload.append('lacation_email', this.$store.state.users.user.listroom[this.model]['email'])
            payload.append('room_id', this.$store.state.users.user.listroom[this.model]['room_id'])

            payload.append('Onlinemeeting', this.Onlinemeeting)
            payload.append('invite', " ")
            payload.append('message', " ")
            payload.append('meeting_type', this.meeting_type + " : " + this.outsider)

            // console.log("test")
            this.$store.dispatch("users/booking", payload)

            // this.startdate = new Date().toISOString().substr(0, 10),
            //     this.enddate = new Date().toISOString().substr(0, 10),
            //     this.starttime = '08:00'
            // this.endtime = '08:29'
            // this.title = null
            // this.email = null
            // this.$store.state.users.user.showlistbookingroom = false
            // this.password = null
            // this.checktime = true
        },
        clear() {
            if (this.$store.state.users.user.showlistbookingroom == false) {
                window.location = "http://linkroom.showcalendar.lifelink.co.th/";

                //     this.$router.push({
                //     name: 'Roommenu'
                // });
            } else {
                this.$store.state.users.user.showlistbookingroom = false
                this.startdate = new Date().toISOString().substr(0, 10),
                    this.starttime = '08:00',
                    this.enddate = new Date().toISOString().substr(0, 10),
                    this.endtime = '08:29'
                this.title = null
                this.model = 0
            }
        },
        plus() {
            // countlistroom
            if (this.model == this.$store.state.users.user.countlistroom) {
                this.model = this.model
            } else {
                this.model = this.model + 1
            }
            // console.log(this.model)
        },
        minus() {
            if (this.model == 0) {
                this.model == 0
            } else {
                this.model = this.model - 1
            }
            // console.log(this.model)
        },
        confirm() {
            this.confirmdialog = true
        },
        closedialog() {
            this.$store.state.users.user.confirmdialog = false
            this.password = null
        },
        closeunavailabledialog() {
            this.$store.state.users.user.unavailabledialog = false
            this.password = null

        },
        getColor(status) {
            if (status == 'ว่าง') return 'primary'
            else if (status != 'ว่าง') return 'grey'
            else return 'green'
        },

    },
    created() {

    
        let user_data = this.$route.params.user_data
        // console.log(user_data)
        this.$store.state.users.user.roomemail = user_data
        // console.log(this.$store.state.users.user.roomemail)
        // if (this.$store.state.users.user.token == null) {
        //     this.$router.push({
        //         name: 'Login'
        //     });
        // }

        let payload = new FormData()
        payload.append('email', user_data)
        this.$store.dispatch("users/getimg",payload)


        var date = new Date();
        var currentHours = date.getHours();
        var m = date.getMinutes();
        // m = ("0" + m).slice(-2);

        if (parseInt(m) < 15) {
            m = "00"
            var me = "59"
            // console.log(1)
        } else if (parseInt(m) >= 15 && parseInt(m) < 45) {
            m = "30"
            var me = "29"
            // currentHours = currentHours + 1
            // console.log(2)
        } else if (parseInt(m) > 45) {
            currentHours = currentHours + 1
            m = "00"
            var me = "59"
            // console.log(3)
        }
        var end = currentHours + 1
        end = ("0" + end).slice(-2);
        currentHours = ("0" + currentHours).slice(-2);
        // console.log((currentHours.toString())+":"+(m.toString()))
        // console.log((end.toString())+":"+(m.toString()))
        this.starttime = (currentHours.toString()) + ":" + (m.toString())
        this.endtime = (end.toString()) + ":" + (me.toString())
    }
}
</script>

<style>
.box {
    background: rgb(74, 197, 177);
    background: linear-gradient(176deg, rgb(74, 197, 177) 0%, rgb(74, 197, 177) 100%);
}

.cpbox {
    background: rgb(74, 197, 177);
    background: linear-gradient(176deg, rgb(74, 197, 177) 0%, rgb(74, 197, 177) 100%);
}

.custom-placeholer-color input::placeholder {
    color: red !important;
    opacity: 1;
}

.custom-label-color .v-label {
    color: red;
    opacity: 1;
}

.v-date-picker-table .v-btn {
    font-size: 34px;
}

.theme--light.v-date-picker-table .v-date-picker-table--date__week,
.theme--light.v-date-picker-table th {
    color: #8fc748 !important;
    font-size: 36px;
}

.v-date-picker-table--date .v-btn {
    height: 40px;
    width: 40px;
}

.theme--dark.v-input input,
.theme--dark.v-input textarea {
    color: #000000 !important;
}

.v-select__selections {
    height: 5vh;
}

.v-select__selection--comma {
    padding: 1vh;
    padding-left: 2vh;
}
</style>
