<template>
<v-container style="padding-top:10vh">
    <v-row justify='start' style="padding-left:3%;padding-right:3%">
        <v-card-title class="black--text font-weight-black">
            เลือกอุปกรณ์ในการประชุม
        </v-card-title>
    </v-row>

    <v-row v-if="!user.mult && user.selectequipments == 'Video Conference'">
        <v-row style="padding-left:3%;padding-right:3%">
            <v-card-text class="back--text text-center subtitle-2">
                Logitech Meetup
            </v-card-text>
        </v-row>
        <v-container fill-height fluid style="padding-top:6%">
            <v-row justify='center' align="center">
                <v-img contain height="60px" width="40px" :src="require('../assets/MeetUp_Front.png')" />
            </v-row>
        </v-container>
        <v-row style="padding-left:3%;padding-right:3%;padding-top:6%" justify='center' outline>
            <v-col cols="12" style="padding-right:5px">
                <v-card elevation="2" width="100%" height="100%" style="border-radius: 15px">
                    <v-card-text class="subtitle text-justify" style="text-indent: 30px">
                        <b class="back--text">Logitech Meetup</b>
                        ทำให้ทุกการประชุม online เป็นเรื่องง่าย ออกแบบสำหรับ
                        ห้องประชุมขนาดเล็ก เหมาะสำหรับผู้เข้าประชุมไม่เกิน 10 ท่าน
                        <p>- รองรับการทำงานร่วมกับโปรแกรมต่างๆ เช่น Zoom, WebEx, Microsoft Teams และ Platform ต่างๆได้อย่างดี</p>
                        <p>- เหมาะสำหรับห้องประชุมขนาดเล็ก ไม่เกิน 10 ท่าน เพื่อได้คุณภาพเสียงที่ดีเยี่ยม </p>
                        <p>- กล้องให้ภาพวีดีโอคมชัด 4k พร้อมความสามารถในการซูม 5 เท่า</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2">
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_minus(0)">
                        <v-icon dark color="black">
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <b style="font-size:20px">{{unit}}</b>
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_add(0)">
                        <v-icon dark color="black">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-text>

            </v-row>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult" @click="borrow">
                <v-card-text class="back--text text-center subtitle-2 ">
                    <v-btn :disabled="!formpassword" width="100%" dark color="box">
                        ยืนยันรายการ
                    </v-btn>
                </v-card-text>
            </v-row>
        </v-container>

    </v-row>

    <v-row v-if="!user.mult && user.selectequipments == 'Interactive Board'">
        <v-row style="padding-left:3%;padding-right:3%">
            <v-card-text class="back--text text-center subtitle-2">
                Interactive Board
            </v-card-text>
        </v-row>
        <v-container fill-height fluid style="padding-top:6%">
            <v-row justify='center' align="center">
                <v-img contain height="100px" width="200px" :src="require('../assets/holion.png')" />
            </v-row>
        </v-container>
        <v-row style="padding-left:3%;padding-right:3%;padding-top:6%" justify='center' outline>
            <v-col cols="12" style="padding-right:5px">
                <v-card elevation="2" width="100%" height="100%" style="border-radius: 15px">
                    <v-card-text class="subtitle text-justify" style="text-indent: 30px">
                        <p>
                        <b class="back--text">Interactive Board</b>
                        แสดง Presentation จาก Laptop เชื่อมต่อแบบไร้สาย
                        </p>
                        <p>- ขีดเขียนวาดลบได้ บันทึกสิ่งที่เขียน และแชร์ผ่าน QR code, Email, USB</p>
                        <p>- รวบรวม 6 ผลิตภัณฑ์ไว้ในอุปกรณ์เดียวคือ โปรเจคเตอร์, คอมพิวเตอร์, Ad Player, Electronic Whiteboard, Welcome Screen, ลำโพง </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2">
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_minus(1)">
                        <v-icon dark color="black">
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <b style="font-size:20px">{{unit}}</b>
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_add(1)">
                        <v-icon dark color="black">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-text>

            </v-row>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2 ">
                    <v-btn :disabled="!formpassword" width="100%" dark color="box"  @click="borrow">
                        ยืนยันรายการ
                    </v-btn>
                </v-card-text>
            </v-row>
        </v-container>

    </v-row>

    <v-row v-if="!user.mult && user.selectequipments == 'Microphone'">
        <v-row style="padding-left:3%;padding-right:3%">
            <v-card-text class="back--text text-center subtitle-2">
                Microphone
            </v-card-text>
        </v-row>
        <v-container fill-height fluid style="padding-top:6%">
            <v-row justify='center' align="center">
                <v-img contain height="150px" width="250px" :src="require('../assets/mi3.png')" />
            </v-row>
        </v-container>
        <v-row style="padding-left:3%;padding-right:3%;padding-top:6%" justify='center' outline>
            <v-col cols="12" style="padding-right:5px">
                <v-card elevation="2" width="100%" height="100%" style="border-radius: 15px">
                    <v-card-text class="subtitle text-justify" style="text-indent: 30px">
                        <p><b class="back--text">Microphone</b></p>
                        <p>- ไมโครโฟนไร้สายคุณภาพสูง</p>
                        <p>- มีระบบกำจัดเสียงรบกวนรอบข้าง </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2">
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_minus(2)">
                        <v-icon dark color="black">
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <b style="font-size:20px">{{unit}}</b>
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_add(2)">
                        <v-icon dark color="black">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-text>

            </v-row>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2 ">
                    <v-btn  :disabled="!formpassword" width="100%" dark color="box"  @click="borrow">
                        ยืนยันรายการ
                    </v-btn>
                </v-card-text>
            </v-row>
        </v-container>

    </v-row>

    <v-row v-if="!user.mult && user.selectequipments == 'Wireless Sharing'">
        <v-row style="padding-left:3%;padding-right:3%">
            <v-card-text class="back--text text-center subtitle-2">
                Wireless Sharing
            </v-card-text>
        </v-row>
        <v-container fill-height fluid style="padding-top:6%">
            <v-row justify='center' align="center">
                <v-img contain height="200px" width="200px" :src="require('../assets/q.png')" />
            </v-row>
        </v-container>
        <v-row style="padding-left:3%;padding-right:3%;padding-top:6%" justify='center' outline>
            <v-col cols="12" style="padding-right:5px">
                <v-card elevation="2" width="100%" height="100%" style="border-radius: 15px">
                    <v-card-text class="subtitle text-justify" style="text-indent: 30px">
                        <b class="back--text">Wireless Sharing</b>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2">
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_minus(3)">
                        <v-icon dark color="black">
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <b style="font-size:20px">{{unit}}</b>
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_add(3)">
                        <v-icon dark color="black">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-text>

            </v-row>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2 ">
                    <v-btn :disabled="!formpassword" width="100%" dark color="box"  @click="borrow">
                        ยืนยันรายการ
                    </v-btn>
                </v-card-text>
            </v-row>
        </v-container>

    </v-row>

    <v-row v-if="!user.mult && user.selectequipments == 'Tablet'">
        <v-row style="padding-left:3%;padding-right:3%">
            <v-card-text class="back--text text-center subtitle-2">
                Tablet
            </v-card-text>
        </v-row>
        <v-container fill-height fluid style="padding-top:6%">
            <v-row justify='center' align="center">
                <v-img contain height="150px" width="150px" :src="require('../assets/ipad.png')" />
            </v-row>
        </v-container>
        <v-row style="padding-left:3%;padding-right:3%;padding-top:6%" justify='center' outline>
            <v-col cols="12" style="padding-right:5px">
                <v-card elevation="2" width="100%" height="100%" style="border-radius: 15px">
                    <v-card-text class="subtitle text-justify" style="text-indent: 30px">
                        <b class="back--text">Tablet</b>
                        <p>- Capacity 128GB</p>
                        <p>- Display 11-inch (diagonal) LED backlit Multi‑Touch </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2">
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_minus(4)">
                        <v-icon dark color="black">
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <b style="font-size:20px">{{unit}}</b>
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_add(4)">
                        <v-icon dark color="black">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-text>

            </v-row>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2 ">
                    <v-btn :disabled="!formpassword" width="100%" dark color="box"  @click="borrow">
                        ยืนยันรายการ
                    </v-btn>
                </v-card-text>
            </v-row>
        </v-container>

    </v-row>

    <v-row v-if="!user.mult && user.selectequipments == 'Lcd'">
        <v-row style="padding-left:3%;padding-right:3%">
            <v-card-text class="back--text text-center subtitle-2">
                Lcd
            </v-card-text>
        </v-row>
        <v-container fill-height fluid style="padding-top:6%">
            <v-row justify='center' align="center">
                <v-img contain height="150px" width="200px" :src="require('../assets/lcd3.png')" />
            </v-row>
        </v-container>
        <v-row style="padding-left:3%;padding-right:3%;padding-top:6%" justify='center' outline>
            <v-col cols="12" style="padding-right:5px">
                <v-card elevation="2" width="100%" height="100%" style="border-radius: 15px">
                    <v-card-text class="subtitle text-justify" style="text-indent: 30px">
                        <b class="back--text">Lcd</b>
                        <p>- Display 4K 42-inch </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2">
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_minus(5)">
                        <v-icon dark color="black">
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <b style="font-size:20px">{{unit}}</b>
                    <v-btn class="mx-4" fab dark x-small color="grey lighten-1" @click="unit_add(5)">
                        <v-icon dark color="black">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-text>

            </v-row>
            <v-row style="padding-left:3%;padding-right:3%" v-if="!user.mult">
                <v-card-text class="back--text text-center subtitle-2 ">
                    <v-btn :disabled="!formpassword" width="100%" dark color="box"  @click="borrow">
                        ยืนยันรายการ
                    </v-btn>
                </v-card-text>
            </v-row>
        </v-container>

    </v-row>

    <v-row v-if="user.mult">
        <v-row style="padding-top:10%;padding-left:3%;padding-right:3%" justify='center' outline>
            <v-col cols="12">
                <v-card width="100%" height="100%" outlined style="border-radius: 15px;">
                    <v-row>
                        <v-col cols="4" style="padding-left:10%">
                            <v-container fill-height fluid>
                                <v-row justify='center' align="center">
                                    <v-img contain height="40px" width="40px" :src="require('../assets/Icon-25.png')" />
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="8" style="padding-left:0%">
                            <v-container fill-height fluid>
                                <v-row justify='center' align="center">
                                    <v-col cols="12">
                                        <b style="padding-left:10%">Video Conference</b>
                                        <p style="margin:0%">
                                            <v-btn class="mx-4" fab dark x-small outlined color="grey lighten-1" @click="unit_minus">
                                                <v-icon dark color="black">
                                                    mdi-minus
                                                </v-icon>
                                            </v-btn>
                                            <b style="font-size:20px">{{unit}}</b>
                                            <v-btn class="mx-4" fab dark x-small outlined color="grey lighten-1" @click="unit_add">
                                                <v-icon dark color="black">
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-top:0%">
                <v-card width="100%" height="100%" outlined style="border-radius: 15px;">
                    <v-row>
                        <v-col cols="4" style="padding-left:10%">
                            <v-container fill-height fluid>
                                <v-row justify='center' align="center">
                                    <v-img contain height="40px" width="40px" :src="require('../assets/Icon-26.png')" />
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="8" style="padding-left:0%">
                            <v-container fill-height fluid>
                                <v-row justify='center' align="center">
                                    <v-col cols="12">
                                        <b style="padding-left:10%">Interactive Board</b>
                                        <p style="margin:0%">
                                            <v-btn class="mx-4" fab dark x-small outlined color="grey lighten-1" @click="unit_minus">
                                                <v-icon dark color="black">
                                                    mdi-minus
                                                </v-icon>
                                            </v-btn>
                                            <b style="font-size:20px">{{unit}}</b>
                                            <v-btn class="mx-4" fab dark x-small outlined color="grey lighten-1" @click="unit_add">
                                                <v-icon dark color="black">
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-top:0%">
                <v-card width="100%" height="100%" outlined style="border-radius: 15px;">
                    <v-row>
                        <v-col cols="4" style="padding-left:10%">
                            <v-container fill-height fluid>
                                <v-row justify='center' align="center">
                                    <v-img contain height="40px" width="40px" :src="require('../assets/Icon-27.png')" />
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="8" style="padding-left:0%">
                            <v-container fill-height fluid>
                                <v-row justify='center' align="center">
                                    <v-col cols="12">
                                        <b style="padding-left:10%">Microphone</b>
                                        <p style="margin:0%">
                                            <v-btn class="mx-4" fab dark x-small outlined color="grey lighten-1" @click="unit_minus">
                                                <v-icon dark color="black">
                                                    mdi-minus
                                                </v-icon>
                                            </v-btn>
                                            <b style="font-size:20px">{{unit}}</b>
                                            <v-btn class="mx-4" fab dark x-small outlined color="grey lighten-1" @click="unit_add">
                                                <v-icon dark color="black">
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

        </v-row>
        <v-row justify="center">
            <v-col cols="10">
                <v-card-text class="back--text text-center subtitle-2 ">
                    <v-btn :disabled="!formpassword" width="100%" dark color="box"  @click="borrow">
                        ยืนยันรายการ
                    </v-btn>
                </v-card-text>
            </v-col>
        </v-row>
    </v-row>
</v-container>
</template>

<script>
import {
    User
} from '../models'
import {
    mapState,
    dispatch,
    mapActions,
    store
} from 'vuex'

export default {
    data: vm => ({
        unit: 0,
    }),
    components: {},
    computed: {
        ...mapState('users', {
            user: 'user'
        }),
        computedDateFormatted() {
            return this.formatDate(this.startdate)
        },
        formpassword() {
            return (
                this.unit
            )
        },
        stateequipments() {
            return this.$store.state.users.user.stateequipments
        },
    },
    watch: {
        startdate(val) {
            this.dateFormatted = this.formatDate(this.startdate)
        },
        enddate(val) {
            this.enddateFormatted = this.formatDate(this.enddate)
        },
        stateequipments() {
            //  console.log(".....")
            this.$router.push({
                name: 'Showroom'
            });
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        menu(menu) {
            this.$router.push({
                name: menu
            });
        },
        unit_add(item) {

            if(this.unit == this.$store.state.users.user.countlistequipments[item].maxcount){
                this.unit = this.unit
            }
            else{
               this.unit++ 
            }
        },
        unit_minus(item) {
            this.unit--
            if (this.unit <= 0) {
                this.unit = 0
            }
        },
        borrow(){
            let payload = new FormData()
            // payload.append('email', this.email)
            payload.append('booking_id', this.$store.state.users.user.showbooking)
            payload.append('quantity', this.unit)
            payload.append('item_group', this.$store.state.users.user.numequipments[this.$store.state.users.user.selectequipments])
            this.$store.dispatch("users/borrowequipments", payload)    
        }

    },
    created() {
        if (this.$store.state.users.user.token == null) {
            this.$router.push({
                name: 'Login'
            });
        }
    }
}
</script>

<style>
.box {
    background: rgb(134, 149, 154);
    background: linear-gradient(211deg, rgba(134, 149, 154, 1) 0%, rgba(43, 49, 75, 1) 100%);
}
</style>
